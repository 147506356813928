import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TotalWeight } from '../../types/result';

const initialState: TotalWeight = {
  totalWeight: 0
};

export const totalWeightSlice = createSlice({
  name: 'weight',
  initialState,
  reducers: {
    totalWeight: (state, action: PayloadAction<any>) => {
      state.totalWeight = action.payload;
    }
  }
});
export const { totalWeight } = totalWeightSlice.actions;
export default totalWeightSlice.reducer;
