import React from 'react';
import styled from 'styled-components/macro';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  CircularProgress as MuiCircularProgress,
  Typography
} from '@mui/material';
import { spacing } from '@mui/system';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SupplierDataTypes, SupplierDataWasteType } from '../../types/result';
import CheckIcon from '@mui/icons-material/Check';
import * as Icon from 'react-feather';

const CircularProgress = styled(MuiCircularProgress)(spacing);

const Paper = styled(MuiPaper)(spacing);

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& th': {
    backgroundColor: theme.palette.grey.A100
  },
  height: 55
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  transition: '0.3s',
  '&:hover': {
    opacity: 0.8,
    transition: '0.3s'
  }
}));

type HeadCell = {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};
const headCells: Array<HeadCell> = [
  {
    id: 'supplier',
    numeric: false,
    disablePadding: true,
    label: 'Supplier'
  },
  { id: 'electronic_waste', numeric: true, disablePadding: false, label: 'Electronic Waste' },
  { id: 'scrap_metals', numeric: true, disablePadding: false, label: 'Scrap Metals' },
  {
    id: 'white_goods_appliances',
    numeric: true,
    disablePadding: false,
    label: 'White Goods/ Appliances'
  },
  { id: 'construction_waste', numeric: true, disablePadding: false, label: 'Construction Waste' },
  { id: 'furniture', numeric: true, disablePadding: false, label: 'Furniture' },
  { id: 'textiles', numeric: true, disablePadding: false, label: 'Textiles' },
  {
    id: 'industrial_equipment',
    numeric: true,
    disablePadding: false,
    label: 'Industrial Equipment'
  },
  { id: 'hazardous_waste', numeric: true, disablePadding: false, label: 'Hazardous Waste' },
  {
    id: 'general_solid_waste_donations',
    numeric: true,
    disablePadding: false,
    label: 'General Solid Waste/ Donations'
  },
  {
    id: 'yard_waste_tree_trimmings',
    numeric: true,
    disablePadding: false,
    label: 'Yard Waste/ Tree Trimmings'
  },
  { id: 'paper_cardboard', numeric: true, disablePadding: false, label: 'Paper/ Cardboard' },
  { id: 'roofing', numeric: true, disablePadding: false, label: 'Roofing' }
];

const SupplierWasteTypes = [
  'Electronic Waste',
  'Scrap Metals',
  'White Goods/ Appliances',
  'Construction Waste',
  'Furniture',
  'Textiles',
  'Industrial Equipment',
  'Hazardous Waste',
  'General Solid Waste/ Donations',
  'Yard Waste/ Tree Trimmings',
  'Paper/ Cardboard',
  'Roofing'
];

const EnhancedTableHead: React.FC = () => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ padding: '6px 16px' }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

type SupplierTableProps = {
  data: SupplierDataTypes[] | undefined;
  isError: boolean;
  isLoading: boolean;
};

export const SupplierTable = ({
  data = [],
  isError,
  isLoading
}: SupplierTableProps): JSX.Element => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ marginBottom: 10 }}>
      {data ? (
        <>
          <Paper>
            <TableContainer sx={{ maxHeight: 640 }}>
              <Table
                stickyHeader
                sx={{ minWidth: 800 }}
                aria-labelledby="tableTitle"
                size={'small'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead />
                <TableBody>
                  {data?.length > 0 && !isError ? (
                    <>
                      {data
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: any, index: number) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow tabIndex={-1} key={row.id}>
                              <TableCell component="th" id={labelId} scope="row" padding="normal">
                                <StyledLink to={`supplier/${row.id}`}>{row.name}</StyledLink>
                              </TableCell>
                              {SupplierWasteTypes.map((el) => (
                                <TableCell key={el} align="center">
                                  {row.wasteTypes
                                    .map((item: SupplierDataWasteType) => item?.name)
                                    .includes(el) ? (
                                    <CheckIcon />
                                  ) : (
                                    <Icon.X />
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        {isLoading ? (
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <CircularProgress m={2} />
                          </Box>
                        ) : (
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              There are no Supplier
                            </Typography>
                          </Paper>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {data?.length > 0 && (
              <TablePagination
                labelRowsPerPage={matches ? 'Rows per page:' : ''}
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          {isError ? <Typography>Something went wrong!</Typography> : <CircularProgress m={2} />}
        </Box>
      )}
    </Box>
  );
};
