import styled from 'styled-components/macro';

type CircleProps = {
  color: string;
};

export const WasteTypeWrapper = styled.div`
  width: 156px;
  height: 156px;
`;

export const List = styled.ul`
  list-style-type: none;
  position: absolute;
  //column-count: 2;
  display: flex;
  flex-wrap: wrap;
  width: 435px;
  height: 170px;
  left: 160px;
  top: 30px;
`;

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  width: 190px;
  color: #344563;
  font-size: 12px;
  margin-right: 7px;
`;

export const Circle = styled.div<CircleProps>`
  width: 14px;
  height: 14px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;

export const Text = styled.span`
  display: inline-block;
  margin-top: -3px;
  margin-left: 5px;
  width: 125px;
  word-break: break-word;
`;

export const Percentage = styled.span`
  margin-top: -3px;
  font-weight: 500;
  color: #344563;
`;

export const LeftPart = styled.div`
  display: flex;
`;
