import React, { memo, useEffect, useMemo, useState } from 'react';
import { array, object } from 'yup';
import styled from 'styled-components/macro';
import {
  Grid,
  Typography,
  Box,
  IconButton as MuiIconButton,
  Button,
  CircularProgress as MuiCircularProgress,
  TextField as MuiTextField,
  FormHelperText
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { spacing } from '@mui/system';
import {
  useGetCurrentSavedQuoteQuery,
  useGetDataPieChartQuery,
  useSaveQuotesMutation,
  useUpdateQuotesMutation
} from '../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_SUPPLIER } from '../../constants';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik, FieldArray } from 'formik';
import { AutocompleteFieldItems } from '../autocomplete/AutocompleteFieldItems';
import { chartSlice } from '../../redux/slices/QuotesSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxHelper';
import { AutocompleteZipCode } from '../autocomplete/AutocompleteZipCode';
import { useSnackbar } from 'notistack';
import useDebounce from '../../hooks/useDebounce';
import useLogOut from '../../hooks/useLogOut';

const IconButton = styled(MuiIconButton)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const StyledForm = styled(Form)({
  position: 'relative'
});

const CircularProgress = styled(MuiCircularProgress)(spacing);

const emptyItems = { waste_item_id: '', quantity: '' };

const RegExp = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
const RegExpClaim = /^[a-zA-Z0-9]*$/;

const DURATION = 500;
const QuantityValidation = (val: string | undefined) => Number(val) <= 1000000;

export const CollectionForm = memo(() => {
  const [valueItems, setValueItems] = useState<any>(null);
  const { selectedItems, clearItems } = chartSlice.actions;
  const { totalWeight } = useAppSelector((state) => state.totalWeightSlice);
  const dispatch = useAppDispatch();
  const [saveQuotes, { isSuccess: isSuccessSave, error: errorSave, data: saveRes }] =
    useSaveQuotesMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { id }: any = useParams();
  const navigate = useNavigate();
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  const {
    data,
    isLoading: isLoadingItems,
    error: errorQuery
  } = useGetCurrentSavedQuoteQuery({ id }, { skip: !id });
  const [updateQuotes, { isSuccess: isSuccessUpdate, error: errorUpdate, data: updatedRes }]: any =
    useUpdateQuotesMutation();

  const NewCalculatorSchema = Yup.object().shape({
    zip_code: Yup.string()
      .required('Zip code is required')
      .matches(RegExp, 'Must be a number')
      .min(5, 'Must be a number with 5 characters')
      .max(5, 'Must be a number with 5 characters'),
    claim_number: Yup.string()
      .max(7, 'Max. 7 characters')
      .matches(RegExpClaim, 'Must contain only letters and numbers'),
    items: array(
      object({
        waste_item_id: Yup.string().required('Item is required'),
        quantity: Yup.string()
          .required('Quantity is required')
          .matches(RegExp, 'Is not valid')
          .test({
            test: QuantityValidation,
            message: 'Should not exceed 1,000,000'
          })
      })
    ).min(1, 'You need to provide at least 1 item')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isSaveButton: false,
      isSearchButton: false,
      claim_number: data?.quote?.claim_number || '',
      zip_code: data?.quote?.zip_code || '',
      items: data?.quote?.items || [emptyItems]
    },
    validationSchema: NewCalculatorSchema,

    onSubmit: async (values) => {
      const updateQuoteHandler = () => {
        return updateQuotes({
          id: id,
          body: {
            claim_number: values?.claim_number,
            zip_code: +values?.zip_code,
            items: values?.items.map((el: any) => {
              return {
                quantity: el.quantity,
                waste_item_id: el.waste_item_id
              };
            })
          }
        }).unwrap();
      };
      const saveQuoteHandler = () => {
        return saveQuotes({
          claim_number: values?.claim_number,
          // zip_code_id: values?.zip_code_id,
          zip_code: +values?.zip_code,
          items: values?.items.map((el: any) => {
            return {
              quantity: el.quantity,
              waste_item_id: el.waste_item_id
            };
          })
        }).unwrap();
      };
      if (values.isSaveButton) {
        if (id) {
          await updateQuoteHandler();
        } else {
          await saveQuoteHandler();
        }
        await setFieldValue('isSaveButton', false);
      } else {
        if (!id) {
          await saveQuoteHandler();
        } else {
          await updateQuoteHandler();
        }
        await setFieldValue('isSaveButton', false);
      }
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    isSubmitting,
    setFieldValue,
    isValid
  } = formik;

  const itemsSearch = useMemo(() => {
    return JSON.stringify(valueItems);
  }, [valueItems]);
  const debouncedItemsSearch = useDebounce<string>(itemsSearch, DURATION);
  const checkSkip = debouncedItemsSearch === 'null';
  const { data: PieChartData } = useGetDataPieChartQuery(
    {
      items: debouncedItemsSearch
    },
    { skip: checkSkip }
  );

  // CLEAR DATA FOR PIE CHART
  useEffect(() => {
    dispatch(clearItems());
    // eslint-disable-next-line
  }, []);

  // SET ID QUOTE TO URL
  useEffect(() => {
    if (saveRes && values.isSearchButton) {
      navigate(`/calculator/${saveRes?.quote?.id}/${PATH_SUPPLIER.RESULT}${totalWeight}`);
    }
    if (id && updatedRes && values.isSearchButton && isSuccessUpdate && values?.items.length > 0) {
      navigate(`/calculator/${id}/${PATH_SUPPLIER.RESULT}${totalWeight}`);
    }
    // eslint-disable-next-line
  }, [saveRes, id, values.isSearchButton, isSuccessUpdate, updatedRes]);

  // SET DATA FOR PIE CHART
  useEffect(() => {
    const itemsCheck = values?.items
      .map(
        (el: any) =>
          !!el.waste_item_id &&
          typeof el.waste_item_id === 'number' &&
          typeof el.quantity === 'number' &&
          !el.quantity.toString().includes('-')
      )
      .includes(false);
    if (!itemsCheck) {
      setValueItems(values.items);
      dispatch(selectedItems(PieChartData));
    }
    // eslint-disable-next-line
  }, [values, PieChartData]);

  // ERRORS NOTIFICATION
  const errorHandler = (error: any, errorText: string) => {
    let message: string | any = errorText;
    console.log(888, error);
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.data?.error) {
      message = error?.data?.error;
    }
    if (error?.originalStatus === 401 || error?.status === 401) {
      setErrStatus(error?.status === 401 ? error?.status : error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };

  useEffect(() => {
    if (isSuccessSave && !id && values.isSaveButton) {
      enqueueSnackbar('Changes saved', {
        variant: 'success'
      });
      navigate(`/calculator/${saveRes?.quote?.id}`);
    }
    if (errorSave && !id) {
      errorHandler(errorSave, 'Invalid');
    }
    if (isSuccessUpdate && !values.isSearchButton) {
      enqueueSnackbar('Updated', {
        variant: 'success'
      });
    }
    if (errorUpdate) {
      errorHandler(errorUpdate, 'Something went wrong!');
    }
    if (errorQuery) {
      errorHandler(errorQuery, 'Cannot find current quote');
    }
    // eslint-disable-next-line
  }, [isSuccessSave, errorSave, isSuccessUpdate, errorUpdate, errorQuery]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);
  return (
    <React.Fragment>
      <FormikProvider value={formik}>
        <StyledForm noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box my={5} display={'flex'} justifyContent={'flex-end'}>
            <Button
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size="0.9rem" /> : undefined}
              type="submit"
              onClick={(e: any) => {
                if (isValid) {
                  setFieldValue('isSaveButton', true);
                }
                handleSubmit(e);
              }}
              variant="contained"
              color="primary"
              value="save"
            >
              {id ? 'Update quote' : 'Save quote for later'}
            </Button>
          </Box>
          <Grid container spacing={6}>
            <Grid item>
              <FormHelperText>Claim Number</FormHelperText>
              <TextField
                placeholder="Text Field"
                variant="outlined"
                fullWidth
                my={2}
                {...getFieldProps('claim_number')}
                error={Boolean(touched.claim_number && errors.claim_number)}
                helperText={touched.claim_number && errors.claim_number}
              />
            </Grid>
          </Grid>
          <Typography variant="h6" gutterBottom mb={3} mt={3}>
            Collection Location?
          </Typography>
          <Grid container spacing={6}>
            <AutocompleteZipCode
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              getFieldProps={getFieldProps}
              touched={touched}
              errors={errors}
              values={values}
            />
          </Grid>
          <Typography variant="h6" gutterBottom mt={6} mb={3}>
            What needs collecting?
          </Typography>
          {id && isLoadingItems && (
            <Box
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                maxHeight: '450px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  background: '#F7F9FC',
                  zIndex: 1,
                  opacity: 0.4
                }}
              />
              <CircularProgress m={2} style={{ zIndex: 2, marginTop: '100px' }} />
            </Box>
          )}
          <FieldArray name="items">
            {({ push, remove }) => (
              <React.Fragment>
                {values.items.map((elem: any, index: number) => (
                  <Box key={index}>
                    <Box display={'flex'} alignItems={'center'} mt={6}>
                      <Typography variant="subtitle1" gutterBottom mb={0} mr={2}>
                        {`Item ${index + 1}`}
                      </Typography>
                      <IconButton
                        aria-label="Delete"
                        mr={2}
                        size="large"
                        disabled={isSubmitting}
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <AutocompleteFieldItems
                      touched={touched}
                      errors={errors}
                      getFieldProps={getFieldProps}
                      index={index}
                      setFieldValue={setFieldValue}
                      isSubmitting={isSubmitting}
                      elem={elem}
                    />
                  </Box>
                ))}

                <Grid item>
                  {typeof errors.items === 'string' ? (
                    <Typography color="error">{errors.items}</Typography>
                  ) : null}
                </Grid>

                <Box width={250} my={4}>
                  <Button
                    disabled={isSubmitting}
                    variant="text"
                    color="primary"
                    onClick={() => push(emptyItems)}
                  >
                    <AddIcon />
                    {typeof errors.items === 'string'
                      ? 'Add Waste Type...'
                      : 'Add Another Waste Type...'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </FieldArray>
          <Box display={'flex'} justifyContent={'center'}>
            <Button
              disabled={isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size="0.9rem" /> : undefined}
              onClick={(e: any) => {
                if (isValid) {
                  setFieldValue('isSearchButton', true);
                }
                handleSubmit(e);
              }}
              type="submit"
              variant="contained"
              color="primary"
              value="search"
            >
              Search
            </Button>
          </Box>
        </StyledForm>
      </FormikProvider>
    </React.Fragment>
  );
});
