import React from 'react';
import styled from 'styled-components/macro';
import { Button, Container } from '@mui/material';
import { Grid } from '@mui/material';
import { Supplier } from '../components/searchSupplier/Supplier';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  height: '100%',
  marginTop: '40px'
});
const GridContainer = styled(Grid)({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
});

export const SupplierPage = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <StyledContainer maxWidth="lg">
      <GridContainer container spacing={6}>
        <Grid item width={'100%'}>
          <Supplier />
        </Grid>
        <Grid item>
          <Button onClick={handleGoBack} variant="contained">
            Back
          </Button>
        </Grid>
      </GridContainer>
    </StyledContainer>
  );
};
