// Themes
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO'
};

const ROOTS_AUTH = '/auth';
const ROOTS_PAGE = '/';

export const PATH_AUTH = {
  ROOT: ROOTS_AUTH,
  LOGIN: `${ROOTS_AUTH}/login`,
  REGISTER: `${ROOTS_AUTH}/register`,
  RESET_PASSWORD: `${ROOTS_AUTH}/reset-password`
};

export const PATH_SUPPLIER = {
  ROOT: ROOTS_PAGE,
  SAVED_QUOTE: '/my-quote',
  CALCULATOR: 'calculator',
  EDIT_SAVED_QUOTE: '/calculator/:id',
  RESULT: 'result',
  CONFIRM: 'confirm',
  DASHBOARD: '/dashboard',
  SEARCH_SUPPLIER: '/search-supplier',
  SUMMARY: '/summary'
};

export const PATH_PAGES = {
  ROOT: ROOTS_PAGE,
  PAGE_404: '/404',
  PAGE_500: '/500'
};
