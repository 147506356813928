import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PieChart } from '../../types/chart';

const initialState: PieChart = {
  data: []
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    selectedItems: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    clearItems: (state) => {
      state.data = [];
    }
  }
});

export default chartSlice.reducer;
