import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Dashboard } from '../types/dachboard';
import { StatisticsData } from '../types';

export const dashboardAPI = createApi({
  reducerPath: 'dashboardAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['Items', 'Statistics'],
  endpoints: (build) => ({
    getDashboardData: build.query<Dashboard, { showAll: boolean; limit: number; offset: number }>({
      query: ({ showAll = false, limit, offset = 0 }) => ({
        url: `auth/quotes/dashboard`,
        params: {
          showAll,
          limit,
          offset
        }
      }),
      providesTags: (result) =>
        result && result.quotes
          ? [
              ...result.quotes.map(({ id }: { id: number }) => ({
                type: 'Items' as const,
                id
              })),
              { type: 'Items', id: 'LIST' }
            ]
          : [{ type: 'Items', id: 'LIST' }]
    }),
    getStatisticsData: build.query<
      StatisticsData,
      { showAll: boolean; dateFrom: string; dateTo: string }
    >({
      query: ({ showAll = false, dateFrom, dateTo }) => ({
        url: `auth/quotes/dashboard/statistics`,
        params: {
          showAll,
          from: dateFrom,
          to: dateTo
        }
      }),
      providesTags: () => ['Statistics']
    })
  })
});

export const { useGetDashboardDataQuery, useGetStatisticsDataQuery } = dashboardAPI;
