import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { ConfirmQuote, ConfirmQuoteData } from '../../types/result';

const initialState: ConfirmQuoteData = {
  confirmed_collections: []
};

export const confirmSlice = createSlice({
  name: 'confirm',
  initialState,
  reducers: {
    confirmedItems: (state, action: PayloadAction<any>) => {
      state.confirmed_collections = [...state.confirmed_collections, action.payload];
    },
    changeItems: (state, action: PayloadAction<any>) => {
      state.confirmed_collections = [action.payload];
    },
    updateItems: (state, action: PayloadAction<any>) => {
      const currentState = current(state);
      // use ts-ignore for the hotfix, need to refactor in the future
      // @ts-ignore
      state.confirmed_collections = currentState.confirmed_collections.map((x) => {
        const item = x as unknown as Array<ConfirmQuote>;
        return item[0].total_weight_lbs === action.payload.total_weight_lbs
          ? [
              {
                ...item[0],
                supplier_container_collection_id: action.payload.supplier_container_collection_id
              }
            ]
          : x;
      });
    },
    clearItems: (state) => {
      state.confirmed_collections = [];
    }
  }
});
export const { confirmedItems, clearItems, updateItems, changeItems } = confirmSlice.actions;

export default confirmSlice.reducer;
