import { ReactNode, createRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { Button } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const DURATION = 3000;
const MAX_SNACK = 3;

type NotistackProviderProps = {
  children: ReactNode;
};
function NotistackProvider({ children }: NotistackProviderProps) {
  // add action to all snackbars
  const notistackRef = createRef<any>();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      maxSnack={MAX_SNACK}
      autoHideDuration={DURATION}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      ref={notistackRef}
      hideIconVariant
      action={(key) => (
        <Button onClick={onClickDismiss(key)}>
          <HighlightOffIcon sx={{ color: 'white' }} />
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;
