const getErrorMessage = (error: any) => {
  let message: string | any = 'Something went wrong!';
  if (error?.data?.errors) {
    [message] = Object.values(error.data.errors).map((item: any) => item.message || item);
  } else if (error?.data?.message) {
    message = error.data.message;
  } else if (error?.data?.error) {
    message = error.data.error;
  } else if (typeof error === 'string') {
    message = error;
  }
  return message;
};

export { getErrorMessage };
