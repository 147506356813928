import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  Box,
  Button,
  CircularProgress as MuiCircularProgress,
  Container,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Grid
} from '@mui/material';
import { SupplierTable } from '../components/searchSupplier/SupplierTable';
import { AutocompleteZipCode } from '../components/autocomplete/AutocompleteZipCode';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { spacing } from '@mui/system';
import {
  useGetContainersTypeQuery,
  useGetSupplierByZipCodeQuery
} from '../services/SupplierServices';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';

interface FormValues {
  service_models: any[];
  isSearch: boolean;
  zip_code: string;
  zip_code_id: string;
}

const CircularProgress = styled(MuiCircularProgress)(spacing);

const StyledForm = styled(Form)({
  position: 'relative',
  width: '100%'
});
const StyledContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  height: '100%',
  marginTop: '40px'
});
const HeaderGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  '&.MuiGrid-item': {
    display: 'flex',
    alignItems: 'center'
  }
});
const RegExp = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
const NewSearchSupplierSchema = Yup.object().shape({
  zip_code: Yup.string()
    .required('Zip code is required')
    .matches(RegExp, 'Must be a number')
    .min(5, 'Must be a number with 5 characters')
    .max(5, 'Must be a number with 5 characters')
});

export const SearchSupplier = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const service_models = state as string[];
  const { id }: Readonly<Params<string>> = useParams();

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      service_models: id ? service_models : [],
      isSearch: false,
      zip_code: id || '',
      zip_code_id: ''
    },
    validationSchema: NewSearchSupplierSchema,

    onSubmit: async (values) => {
      navigate(`/search-supplier/${values.zip_code}`, { state: values?.service_models });
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, values, isSubmitting, setFieldValue } =
    formik;

  const { data, error, isLoading, isError } = useGetSupplierByZipCodeQuery(
    {
      id: id as string,
      service_models: JSON.stringify(values?.service_models)
    },
    { skip: !id }
  );

  const { data: serviceModel = { data: [] } } = useGetContainersTypeQuery({});

  const submitHandler = useCallback(() => {
    handleSubmit();
    setFieldValue('isSearch', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serviceModel?.data?.length && !service_models) {
      const newVal = serviceModel?.data?.map((v) => v.id.toString());
      setFieldValue('service_models', newVal);
    }
  }, [serviceModel, setFieldValue, service_models]);

  return (
    <StyledContainer maxWidth="lg">
      <FormikProvider value={formik}>
        <StyledForm noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <HeaderGrid container spacing={6}>
                <Grid item>
                  <Typography p={5} variant={'h3'}>
                    Search Supplier by Zip code
                  </Typography>
                </Grid>
                <AutocompleteZipCode
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  getFieldProps={getFieldProps}
                  touched={touched}
                  errors={errors}
                  values={values}
                  disableHelperTest
                  errorProps={error}
                />
                <Grid item>
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button
                      disabled={isSubmitting}
                      startIcon={
                        isLoading && !data?.length ? <CircularProgress size="0.9rem" /> : undefined
                      }
                      onClick={submitHandler}
                      type="submit"
                      variant="contained"
                      color="primary"
                      value="search"
                    >
                      Search
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" fullWidth>
                    <FormLabel component="legend" focused={false}>
                      Include results for...
                    </FormLabel>
                    <Grid container>
                      {serviceModel?.data.map(({ name, id }) => (
                        <Grid item xs={6} key={id}>
                          <FormControlLabel
                            sx={{ width: '100%' }}
                            label={name}
                            control={
                              <Checkbox
                                name="service_models"
                                value={id}
                                defaultChecked={
                                  service_models ? service_models.includes(id.toString()) : true
                                }
                                onChange={formik?.handleChange}
                              />
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FormControl>
                </Grid>
              </HeaderGrid>
            </Grid>
            <Grid item xs={12}>
              <Typography pl={5} variant={'h5'}>
                Showing result for: {id}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SupplierTable data={data} isError={isError} isLoading={isLoading} />
            </Grid>
          </Grid>
        </StyledForm>
      </FormikProvider>
    </StyledContainer>
  );
};
