import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  Box,
  CircularProgress as MuiCircularProgress,
  Container,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { Grid } from '@mui/material';
import { MyQuoteTable } from '../components/MyQuoteTable';
import { useGetSavedQuoteQuery } from '../services';
import useDebounce from '../hooks/useDebounce';
import { useSnackbar } from 'notistack';
import { usePagination } from '../hooks/usePagination';
import { spacing } from '@mui/system';
import { getErrorMsg } from '../utils/getErrorMsg';
import useAuth from '../hooks/useAuth';

const RootStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;
const CircularProgress = styled(MuiCircularProgress)(spacing);

export const MyQuote = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { onSearch, onChangeRowsPerPage, onChangePage, pagination } = usePagination();
  // Request
  const { data, isLoading, error, isError }: any = useGetSavedQuoteQuery(
    {
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage,
      search: useDebounce<string | undefined>(pagination.search, 500) || ''
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    error && enqueueSnackbar(getErrorMsg(error), { variant: 'error' });
  }, [error, enqueueSnackbar]);

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container my={10}>
          <Grid item xs={12} sm={8} p={5}>
            <Typography variant={'h3'}>Saved Indicative Quotes</Typography>
          </Grid>
          <Grid item xs={12} sm={4} p={5}>
            <TextField
              type="search"
              name="search"
              label="Search"
              autoComplete="off"
              helperText={`Search by ${
                user?.is_quote_viewer ? 'email, ' : ''
              }claim number or quote ref`}
              onChange={onSearch}
              value={pagination?.search || ''}
              fullWidth
              InputProps={{
                endAdornment: isLoading && (
                  <InputAdornment position="end">
                    <MuiCircularProgress size={20} color="secondary" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {data?.quotes ? (
              <MyQuoteTable
                quotes={data?.quotes}
                count={data?.count}
                page={pagination?.page}
                rowsPerPage={pagination?.rowsPerPage}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            ) : (
              <Box display="flex" alignItems="center" justifyContent="center">
                {isError ? <Typography>Something went wrong!</Typography> : <CircularProgress />}
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
};
