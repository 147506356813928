import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  Container,
  Typography as TypographyDash,
  CircularProgress as MuiCircularProgress,
  Grid,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { SummaryTable } from '../components/SummaryTable';
import { spacing } from '@mui/system';
import { useGetDashboardDataQuery } from '../services/DashboardServices';
import useAuth from '../hooks/useAuth';
import { usePagination } from '../hooks/usePagination';
import useErrorHandler from '../hooks/useErrorHandler';

const CircularProgress = styled(MuiCircularProgress)(spacing);

const CLAIM_OPTIONS = [
  {
    value: 1,
    label: 'All claims'
  },
  {
    value: 0,
    label: 'My claims'
  }
];

const RootStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;

const Typography = styled(TypographyDash)`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

export const Summary = () => {
  const { errorHandler } = useErrorHandler();
  const { user } = useAuth();

  const [showAll, setShowAll] = useState(CLAIM_OPTIONS[0].value);
  const { onChangeRowsPerPage, onChangePage, pagination } = usePagination();

  const { data, isError, error, isLoading, isFetching } = useGetDashboardDataQuery(
    {
      showAll: user?.is_quote_viewer ? !!showAll : false,
      limit: pagination.rowsPerPage,
      offset: pagination.page * pagination.rowsPerPage
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleChange = (e: SelectChangeEvent<number>): void => {
    const { value } = e.target;
    setShowAll(Number(value));
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
    // eslint-disable-next-line
    }, [error]);

  useEffect(() => {
    onChangePage(null, 0);
    // eslint-disable-next-line
  }, [showAll]);

  return (
    <RootStyle>
      <Container maxWidth="xl">
        <Typography p={5} variant={'h3'}>
          Welcome to Phoenix Waste Collection Summary
        </Typography>
        {data ? (
          <>
            <Grid container spacing={6}>
              {user?.is_quote_viewer ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    paddingTop: 0
                  }}
                >
                  <Select sx={{ width: '110px' }} value={showAll} onChange={handleChange}>
                    {CLAIM_OPTIONS.map(({ label, value }) => (
                      <MenuItem key={`claim-option-${value}`} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12}>
                <SummaryTable
                  isLoading={isLoading}
                  isFetching={isFetching}
                  data={data?.result.quotes}
                  count={data?.count}
                  page={pagination?.page}
                  rowsPerPage={pagination?.rowsPerPage}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center">
            {isError ? <Typography>Something went wrong!</Typography> : <CircularProgress m={2} />}
          </Box>
        )}
      </Container>
    </RootStyle>
  );
};
