import React from 'react';
import styled from 'styled-components/macro';
import {
  Box,
  CircularProgress as MuiCircularProgress,
  Grid,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { Params, useParams } from 'react-router-dom';
import {
  useGetCountyQuery,
  useGetSupplierQuery,
  useGetContainersTypeQuery,
  useGetWasteTypesQuery
} from '../../services/SupplierServices';
import { spacing } from '@mui/system';
import { SupplierServiceAccordion } from './SupplierServiceAccordion';

const CircularProgress = styled(MuiCircularProgress)(spacing);

const TitleStyle = styled('span')(() => ({
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '18px'
}));
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& th': {
    backgroundColor: theme.palette.grey.A100
  }
}));
const BoxHeader = styled(Box)(({ theme }) => ({
  padding: 16,
  backgroundColor: theme?.palette?.grey?.A100,
  borderBottom: '1px solid rgba(224,224,224,1)',
  '& p': {
    fontWeight: 500,
    lineHeight: '1.3928571428571428rem'
  }
}));

export const Supplier: React.FC = () => {
  const { supplierId }: Readonly<Params<string>> = useParams();
  const { data: supplierData, isLoading, isError } = useGetSupplierQuery({ id: supplierId });
  const { data: countyData } = useGetCountyQuery({});
  const { data: containersType } = useGetContainersTypeQuery({});
  const { data: wasteTypes, isLoading: wasteTypesLoad } = useGetWasteTypesQuery(
    { limit: 3000, waste_types: JSON.stringify(supplierData?.waste_types_ids) },
    { skip: !supplierData?.waste_types_ids?.length }
  );

  const countyArray = countyData?.data?.map((el) => {
    if (supplierData?.counties_ids?.map((item) => item).includes(el.id)) {
      return el.name;
    }
    return null;
  });
  const containersArray = containersType?.data
    ?.map((el) => {
      if (supplierData?.collection_types_ids?.map((item) => item).includes(el.id)) {
        return el;
      }
      return null;
    })
    .filter((v) => !!v);
  const wasteTypesCollected = wasteTypes?.data?.filter(
    (v) => v && supplierData?.waste_items_ids?.includes(v?.id)
  );

  return (
    <>
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          {isError ? <Typography>Something went wrong!</Typography> : <CircularProgress m={2} />}
        </Box>
      ) : (
        <Grid container spacing={3} sx={{ paddingBottom: 2 }}>
          <Grid item>
            <Typography pb={3} variant={'h3'}>
              {supplierData?.supplier?.company_name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ padding: '20px' }}>
              <Stack spacing={3}>
                <Typography variant="h6" gutterBottom component="span">
                  <TitleStyle>Email Address: </TitleStyle>
                  {supplierData?.supplier?.email}
                </Typography>
                <Typography variant="h6" gutterBottom component="span">
                  <TitleStyle>Website: </TitleStyle>
                  {supplierData?.supplier?.website}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="left">County</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {countyArray?.map(
                      (el, index) =>
                        el && (
                          <TableRow key={`${el}` + index}>
                            <TableCell align={'left'} sx={{ padding: '5px 15px' }}>
                              <Typography variant="subtitle2" gutterBottom component="span">
                                {el}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <BoxHeader>
              <Typography>Collection method & Pricing</Typography>
            </BoxHeader>
            <Paper sx={{ width: '100%', overflow: 'auto', maxHeight: 440 - 55.28 }}>
              {containersArray?.map(
                (el, index) =>
                  el && (
                    <SupplierServiceAccordion
                      key={`${el?.id}` + index}
                      isDefaultOpened={index === 0}
                      id={`${el?.id}`}
                      supplierId={`${supplierId}`}
                      name={el?.name}
                    />
                  )
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="left">Waste collected</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {wasteTypesLoad && (
                      <TableRow>
                        <TableCell align="center" sx={{ padding: '5px 15px' }}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {wasteTypesCollected?.map((v) => (
                      <TableRow key={v?.id}>
                        <TableCell align={'left'} sx={{ padding: '5px 15px' }}>
                          <Typography variant="subtitle2" gutterBottom component="span">
                            {v?.description}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};
