import React, { useEffect, useMemo } from 'react';
import { TableCell, TableRow } from '@mui/material';
import styled from 'styled-components/macro';
import { useAppDispatch } from '../../hooks/useReduxHelper';
import { totalWeightSlice } from '../../redux/slices/TotalWeight';
import { formatNumber } from '../../utils/formatHelpers';

const StyledTableCellTotal = styled(TableCell)(() => ({
  borderTop: '1px solid rgba(224,224,224,1)',
  fontWeight: 'bold'
}));
// eslint-disable-next-line no-empty-pattern
const StyledTableRowTotal = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  position: 'sticky',
  bottom: 0,
  zIndex: 11,
  height: '50px',
  fontWeight: 'bold'
}));
type ResultProps = {
  resultData: any;
  selectedSupplierIndex: number;
};
const SumFunction = (data: any) => {
  return data?.reduce((sum: number, current: number) => {
    return sum + (current || 0);
  }, 0);
};

export const TableTotal: React.FC<ResultProps> = ({ resultData, selectedSupplierIndex }) => {
  const { totalWeight } = totalWeightSlice.actions;
  const dispatch = useAppDispatch();
  const row = resultData?.result?.map((row: any) => row);
  const rowWithoutError = row?.filter((el: any) => !el.recommended_supplier_collection.error);
  const WeightResultMap = rowWithoutError?.map((el: any) => el?.total_weight_lbs);
  const RecycleResultMap = useMemo(
    () =>
      rowWithoutError?.map((el: any) => {
        const item = el?.recommended_supplier_collection?.data[selectedSupplierIndex]
          ?.recycling_rate
          ? el?.recommended_supplier_collection?.data[selectedSupplierIndex]?.recycling_rate
          : el?.recommended_supplier_collection?.data[selectedSupplierIndex]?.recyclingRate;
        if (!item) {
          return el?.recommended_supplier_collection?.data[0]?.recycling_rate
            ? el?.recommended_supplier_collection?.data[0]?.recycling_rate
            : el?.recommended_supplier_collection?.data[0]?.recyclingRate;
        }
        return item;
      }),
    [rowWithoutError, selectedSupplierIndex]
  );

  const PriceResultMap = useMemo(
    () =>
      rowWithoutError?.map((el: any) => {
        if (!el?.recommended_supplier_collection.error) {
          const item = el?.recommended_supplier_collection?.data[selectedSupplierIndex]
            ?.price_per_collection
            ? el?.recommended_supplier_collection?.data[selectedSupplierIndex]?.price_per_collection
            : el?.recommended_supplier_collection?.data[selectedSupplierIndex]?.pricePerCollection;
          if (!item) {
            return el?.recommended_supplier_collection?.data[0]?.price_per_collection
              ? el?.recommended_supplier_collection?.data[0]?.price_per_collection
              : el?.recommended_supplier_collection?.data[0]?.pricePerCollection;
          }
          return item;
        }
        return 0;
      }),
    [rowWithoutError, selectedSupplierIndex]
  );

  const WeightResult = SumFunction(WeightResultMap);
  const PriceResult = SumFunction(PriceResultMap);
  const CheckEmptyData = row?.map(
    (el: any) => el?.recommended_supplier_collection?.data?.length === 0
  );

  const totalRecyclePercentage = useMemo(() => {
    const numerator = WeightResultMap.reduce(
      (accum: number, current: number, index: number) =>
        accum + (current || 0) * (RecycleResultMap[index] || 0),
      0
    );
    const denominator = WeightResultMap.reduce(
      (accum: number, current: number) => accum + (current || 0),
      0
    );
    return (numerator / denominator) * 100;
  }, [WeightResultMap, RecycleResultMap]);

  useEffect(() => {
    dispatch(totalWeight(WeightResult));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WeightResult]);

  return (
    CheckEmptyData.includes(false) && (
      <StyledTableRowTotal>
        <StyledTableCellTotal colSpan={3} />
        <StyledTableCellTotal align="center">Total:</StyledTableCellTotal>
        <StyledTableCellTotal align="center">
          {formatNumber(WeightResult || 0)}
        </StyledTableCellTotal>
        <StyledTableCellTotal align="center">
          {!PriceResult ? null : PriceResult > 0 ? `$${PriceResult}` : 'FREE'}
        </StyledTableCellTotal>
        <StyledTableCellTotal align="center">
          {totalRecyclePercentage?.toFixed(2)} {totalRecyclePercentage && '%'}
        </StyledTableCellTotal>
      </StyledTableRowTotal>
    )
  );
};
