import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import {
  FormHelperText,
  Grid,
  TextField as MuiTextField,
  Button,
  Typography,
  Box
} from '@mui/material';
import { spacing } from '@mui/system';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { useGetItemsQuery, useSendNotificationMutation } from '../../services';
import { CollectionItems, SetFieldType } from '../../types';
import useDebounce from '../../hooks/useDebounce';
import { CircularProgress } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import Link from '@mui/material/Link';
import { useSnackbar } from 'notistack';
import Popper from '@mui/material/Popper';
import useLogOut from '../../hooks/useLogOut';
import useObserver from '../../hooks/useObserver';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.option}`]: {
    ':hover': {
      backgroundColor: '#F3F6F9'
    }
  }
});
const DURATION = 500;

type PropTypes = {
  errors: any;
  touched: any;
  getFieldProps: any;
  index: number;
  setFieldValue: any;
  isSubmitting: any;
  elem: any;
};

const LIMIT = 20;

export const AutocompleteFieldItems: React.FC<PropTypes> = memo(
  ({ errors, touched, getFieldProps, index, setFieldValue, isSubmitting, elem }) => {
    const [inputValueItem, setInputValueItem] = useState<string>('');
    const [valueItem, setValueItem] = useState<any | null>(null);
    const [limit, setLimit] = useState<number>(LIMIT);
    const [quantity, setQuantity] = useState<any>(null);
    const [sendVal, setSendVal] = useState<any>(null);
    const [success, setSuccess] = useState(false);
    const [errStatus, setErrStatus] = useState(null);
    const { logOut } = useLogOut(errStatus);
    const debouncedValueItems = useDebounce<string>(inputValueItem, DURATION);
    const { enqueueSnackbar } = useSnackbar();
    const [
      sendNotification,
      { isSuccess: isSuccessSend, isLoading: isLoadingSend, error: errorSend }
    ]: any = useSendNotificationMutation();
    const {
      data: Items = {
        data: []
      },
      isLoading,
      error: itemError
    } = useGetItemsQuery(
      { search: debouncedValueItems, limit: limit },
      { refetchOnMountOrArgChange: true }
    );

    const itemsOptions: CollectionItems[] = Items?.data?.map((el: CollectionItems) => {
      return {
        description: el.description,
        id: el.id,
        unit: el.unit,
        waste_type: el.waste_type,
        waste_type_id: el.waste_type_id
      };
    });

    const initVal: any = {
      description: elem.description ?? '',
      waste_item_id: elem.waste_item_id ?? ''
    };
    const errorHandler = (error: any) => {
      let message: string | any = 'Something went wrong!';
      if (error?.data?.errors) {
        const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
        message = msg[0];
      }
      if (error?.originalStatus === 401) {
        setErrStatus(error?.originalStatus);
        message = 'You are not authorized!';
      }
      enqueueSnackbar(message, {
        variant: 'error'
      });
    };
    const ref: any = useRef<HTMLUListElement>(null);

    // CHECKING IF QUANTITY CONTAINS 0 AND IF HAS A POINT
    const newValQuantity = useMemo(() => {
      const sliceVal = quantity?.slice(0, 2);
      const sliceFirstVal = quantity?.slice(0, 1);
      if (sliceVal?.includes('0.')) {
        return quantity;
      } else if (sliceFirstVal?.includes('0')) {
        return quantity?.replace(sliceVal, '0');
      } else return quantity;
    }, [quantity]);

    const NoOptionComponent = useMemo(() => {
      const handleSendMail = () => {
        setSendVal(inputValueItem);
        if (sendVal !== inputValueItem) {
          sendNotification({
            body: {
              inputValue: inputValueItem
            }
          });
        }
      };
      return (
        <Box key={index} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
          <Button
            variant="outlined"
            color={success ? 'success' : 'primary'}
            type="button"
            sx={{ color: 'black', cursor: 'default' }}
            startIcon={
              success ? <CheckIcon /> : isLoadingSend && <CircularProgress size="0.9rem" />
            }
          >
            <Typography>
              {success ? (
                'Done'
              ) : isLoadingSend ? (
                'Sending'
              ) : (
                <>
                  No Options - Please{' '}
                  <Link sx={{ cursor: 'pointer' }} underline="always" onClick={handleSendMail}>
                    email
                  </Link>{' '}
                  appsupport@pentatonic.com to request item to be added.
                </>
              )}
            </Typography>
          </Button>
        </Box>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValueItem, success, isLoadingSend]);

    useEffect(() => {
      if (quantity) {
        setFieldValue(`items.${index}.quantity`, Number(newValQuantity));
      }
      if (quantity === '') {
        setFieldValue(`items.${index}.quantity`, '');
      }
      // eslint-disable-next-line
    }, [quantity]);

    useEffect(() => {
      if (sendVal !== inputValueItem) {
        setSuccess(false);
      } else {
        setSuccess(isSuccessSend);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValueItem, isSuccessSend]);

    useEffect(() => {
      if (errorSend) {
        errorHandler(errorSend);
      }
      if (itemError) {
        errorHandler(itemError);
      }
      // eslint-disable-next-line
      }, [errorSend, itemError]);
    useEffect(() => {
      if (errStatus && typeof logOut === 'function') {
        logOut();
      }
    }, [errStatus, logOut]);
    useEffect(() => {
      setLimit(LIMIT);
    }, [inputValueItem]);

    const { pageNum, setLastElement } = useObserver();

    useEffect(() => {
      if (pageNum > 1) {
        setLimit(limit + 10);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNum]);
    return (
      <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item>
          <FormHelperText>Item</FormHelperText>
          <Autocomplete
            disabled={isSubmitting}
            loading={isLoading}
            PopperComponent={StyledPopper}
            ListboxProps={{
              // There are type script warning but its worked fine
              ref: ref
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id} ref={setLastElement}>
                  {option.description}
                </li>
              );
            }}
            options={itemsOptions}
            getOptionLabel={(option) => option.description}
            isOptionEqualToValue={(option, value) => option.description === value.description}
            noOptionsText={!isLoading && NoOptionComponent}
            value={elem?.description === undefined ? null : initVal}
            onChange={(event: any, newValue: SetFieldType) => {
              if (newValue === null) {
                setFieldValue(`items.${index}.quantity`, '');
                setFieldValue(`items.${index}.waste_item_id`, '');
                setQuantity(null);
              }
              setValueItem(newValue);
              setFieldValue(`items.${index}.waste_item_id`, newValue?.id ?? '');
              setFieldValue(`items.${index}.description`, newValue?.description);
            }}
            inputValue={inputValueItem}
            onInputChange={(event, newInputValue) => {
              setInputValueItem(newInputValue as string);
            }}
            id="controllable-states-demo"
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...getFieldProps(`items[${index}].waste_item_id`)}
                placeholder="Washing Machine"
                variant="outlined"
                my={2}
                error={Boolean(
                  touched?.items &&
                    errors?.items &&
                    touched?.items[index]?.waste_item_id &&
                    errors?.items[index]?.waste_item_id
                )}
                helperText={
                  touched?.items &&
                  errors?.items &&
                  touched?.items[index]?.waste_item_id &&
                  errors?.items[index]?.waste_item_id
                }
              />
            )}
          />
        </Grid>
        <Grid item>
          <FormHelperText>Waste Type</FormHelperText>
          <TextField
            disabled={isSubmitting}
            {...getFieldProps(`items[${index}].waste_type`)}
            inputProps={{ readOnly: true }}
            placeholder="White Goods"
            variant="outlined"
            my={2}
            value={elem?.description ? valueItem?.waste_type ?? elem?.waste_type : ''}
          />
        </Grid>
        <Grid item maxWidth={150}>
          <FormHelperText>Quantity</FormHelperText>
          <TextField
            {...getFieldProps(`items[${index}].quantity`)}
            disabled={isSubmitting}
            placeholder="Quantity"
            variant="outlined"
            type="number"
            value={elem?.description ? newValQuantity ?? elem?.quantity : ''}
            onChange={(event: any) => {
              if (!elem?.description && event.target.value) {
                enqueueSnackbar('You must choose an item first', {
                  variant: 'warning'
                });
              }
              if (elem?.description) {
                setQuantity(event.target.value);
              }
            }}
            inputProps={{
              min: '0',
              max: '1000000'
            }}
            fullWidth
            my={2}
            error={Boolean(
              touched?.items &&
                errors?.items &&
                touched?.items[index]?.quantity &&
                errors?.items[index]?.quantity
            )}
            helperText={
              touched?.items &&
              errors?.items &&
              touched?.items[index]?.quantity &&
              errors?.items[index]?.quantity
            }
          />
        </Grid>
        <Grid item maxWidth={100}>
          <FormHelperText>Unit</FormHelperText>
          <TextField
            disabled={isSubmitting}
            {...getFieldProps(`items[${index}].unit`)}
            inputProps={{ readOnly: true }}
            placeholder="Sqr Ft"
            variant="outlined"
            my={2}
            value={elem?.description ? valueItem?.unit ?? elem?.unit : ''}
          />
        </Grid>
      </Grid>
    );
  }
);
