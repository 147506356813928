import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Total } from '../../types/result';

const initialState: Total = {
  totalPriceData: []
};

export const totalSlice = createSlice({
  name: 'total',
  initialState,
  reducers: {
    savePrice: (state, action: PayloadAction<any>) => {
      state.totalPriceData = action.payload;
    },
    updatePrice: (state, action: PayloadAction<any>) => {
      state.totalPriceData = state.totalPriceData?.map((obj: any) => {
        return obj.index === action.payload.index ? action.payload : obj;
      });
    }
  }
});
export const { savePrice, updatePrice } = totalSlice.actions;
export default totalSlice.reducer;
