import React, { memo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { COLORS_ARRAY, DOUGHNUT_WASTE_TYPES_OPTIONS } from '../chartHelpers';
import { ChartCard } from '../ChartCard';
import { StatisticsData } from '../../../types';
import {
  WasteTypeWrapper,
  List,
  Item,
  Circle,
  Text,
  Percentage,
  LeftPart
} from './WasteTypesChart.styled';

type WasteTypesChartProps = {
  data: StatisticsData;
};

export const WasteTypesChart: React.FC<WasteTypesChartProps> = memo(({ data }) => {
  const parsedData = data?.total.waste_category
    .filter((item) => item.percentage && item.percentage !== 0)
    .sort((first, second) => (first.percentage < second.percentage ? 1 : -1));

  return (
    <ChartCard
      title="Waste types"
      sx={{ height: '246px', alignItems: 'flex-start', position: 'relative' }}
    >
      <WasteTypeWrapper>
        <Doughnut
          options={DOUGHNUT_WASTE_TYPES_OPTIONS}
          data={{
            labels: parsedData.map((item) => item.name),
            datasets: data
              ? [
                  {
                    data: parsedData.map((item) => item.percentage.toFixed(2)),
                    backgroundColor: COLORS_ARRAY,
                    borderColor: COLORS_ARRAY,
                    borderWidth: 1
                  }
                ]
              : []
          }}
        />
      </WasteTypeWrapper>
      <List>
        {parsedData.map((item, index) => (
          <Item key={item.name}>
            <LeftPart>
              <Circle color={COLORS_ARRAY[index]} />
              <Text>{item.name}</Text>
            </LeftPart>
            <Percentage>{item.percentage.toFixed(2)}%</Percentage>
          </Item>
        ))}
      </List>
    </ChartCard>
  );
});
