export const formatNumber = (value: number): string =>
  new Intl.NumberFormat().format(Math.round(value));

export const formatToK = (num: number): string | number => {
  const countOfThousands = Math.abs(num) / 1000;
  if (Math.abs(num) > 999) {
    return Math.sign(num) * countOfThousands + 'k';
  }
  return Math.sign(num) * Math.abs(num);
};
