import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CollectionItems, QuoteType, ZipCode } from '../types';
import { PieChart } from '../types/chart';

interface IPaginationReq {
  limit: number;
  offset: number;
  search: string;
}

type CalculatorListData = {
  data: ZipCode;
};
type CalculatorListDataItems = {
  data: CollectionItems;
};
const emailIndex = 2;

export const calculatorAPI = createApi({
  reducerPath: 'calculatorAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['ZipCode', 'Items', 'SaveQuote', 'PieChart', 'CurrentQuote', 'ExportQuote'],
  endpoints: (build) => ({
    getZipCode: build.query<CalculatorListData, { search: string; limit: number }>({
      query: ({ search = '', limit = 20 }) => ({
        url: `location/served`,
        params: {
          search: search,
          limit: limit
        }
      }),
      providesTags: () => ['ZipCode']
    }),
    getItems: build.query<CalculatorListDataItems, { search: string; limit: number }>({
      query: ({ search = '', limit = 20 }) => ({
        url: `waste-items`,
        params: {
          search: search,
          limit: limit
        }
      }),
      providesTags: () => ['Items']
    }),
    getDataPieChart: build.query<PieChart, { items?: any }>({
      query: ({ items }) => ({
        url: `auth/quotes/summary`,
        params: {
          items: items
        }
      }),
      providesTags: () => ['PieChart']
    }),
    saveQuotes: build.mutation<QuoteType, QuoteType>({
      query: (result) => ({
        url: `auth/quotes`,
        method: 'POST',
        body: result
      }),
      invalidatesTags: [{ type: 'SaveQuote', id: 'LIST' }]
    }),
    updateQuotes: build.mutation<QuoteType, { id: any; body: any }>({
      query: ({ id, body }) => ({
        url: `auth/quotes/${id}`,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: [{ type: 'SaveQuote', id: 'LIST' }]
    }),
    deleteQuotes: build.mutation<QuoteType, { id: any }>({
      query: ({ id }) => ({
        url: `auth/quotes/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'SaveQuote', id: 'LIST' }]
    }),
    getSavedQuote: build.query<QuoteType, IPaginationReq>({
      query: ({ limit, offset, search }) => ({
        url: '/auth/quotes',
        params: { limit, offset, search }
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.quotes.map(({ id }: { id: number }) => ({
                type: 'SaveQuote' as const,
                id
              })),
              { type: 'SaveQuote', id: 'LIST' }
            ]
          : [{ type: 'SaveQuote', id: 'LIST' }]
    }),
    getCurrentSavedQuote: build.query<QuoteType, { id: any }>({
      query: ({ id }) => `auth/quotes/${id}`,
      providesTags: () => [{ type: 'SaveQuote', id: 'LIST' }]
    }),
    exportQuote: build.query<
      string,
      { id: number | string | null; ref: number | string; formatIndex: number }
    >({
      query: ({ id, ref, formatIndex }) => ({
        url: `auth/quotes/${id}/export?type=${formatIndex + 1}`,
        headers: {
          Accept: 'application/octet-stream'
        },
        responseHandler: (response: any) =>
          formatIndex < emailIndex &&
          response
            .blob()
            .then((blob: any) => URL.createObjectURL(blob))
            .then((url: any) => {
              const link = document.createElement('a');
              link.href = url;
              link.download = `Quote-${ref}`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
      }),
      providesTags: () => ['ExportQuote']
    }),
    sendNotification: build.mutation<QuoteType, { body: any }>({
      query: ({ body }) => ({
        url: `auth/quotes/notify`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetZipCodeQuery,
  useGetItemsQuery,
  useSaveQuotesMutation,
  useGetDataPieChartQuery,
  useGetSavedQuoteQuery,
  useGetCurrentSavedQuoteQuery,
  useUpdateQuotesMutation,
  useDeleteQuotesMutation,
  useExportQuoteQuery,
  useSendNotificationMutation
} = calculatorAPI;
