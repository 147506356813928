import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ContainersTypeSupplier,
  CountySupplierData,
  CurrentSupplierTypes,
  DefaultListParams,
  DefaultResData,
  ServiceItem,
  SupplierDataTypes,
  WasteTypeCollectedItem
} from '../types/result';

export const supplierAPI = createApi({
  reducerPath: 'supplierAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['SupplierByZipCode', 'Supplier', 'County', 'ContainersType'],
  endpoints: (build) => ({
    getSupplierByZipCode: build.query<
      SupplierDataTypes[] | undefined,
      { id: string | number | null; service_models?: string }
    >({
      query: ({ id, service_models }) => ({
        url: `suppliers/serve/${id}`,
        params: { service_models }
      }),
      providesTags: () => ['SupplierByZipCode']
    }),
    getSupplier: build.query<CurrentSupplierTypes, { id: string | undefined }>({
      query: ({ id }) => ({
        url: `suppliers/${id}`
      }),
      providesTags: () => ['Supplier']
    }),
    getCounty: build.query<CountySupplierData, {}>({
      query: () => ({
        url: `location/county/data`
      }),
      providesTags: () => ['County']
    }),
    getContainersType: build.query<ContainersTypeSupplier, {}>({
      query: () => ({
        url: `containers-types`
      }),
      providesTags: () => ['ContainersType']
    }),
    getServiceData: build.query<
      DefaultResData<ServiceItem[]>,
      DefaultListParams<{ supplierId: string; serviceId: string }>
    >({
      query: ({ limit, offset, search, serviceId, supplierId }) => ({
        url: `/suppliers/${supplierId}/${serviceId}`,
        params: { limit, offset, search }
      })
    }),
    getWasteTypes: build.query<
      DefaultResData<WasteTypeCollectedItem[]>,
      DefaultListParams<{ waste_types: string }>
    >({
      query: ({ limit, offset, search, waste_types }) => ({
        url: '/waste-items',
        params: {
          limit,
          offset,
          search,
          waste_types
        }
      })
    })
  })
});

export const {
  useGetSupplierByZipCodeQuery,
  useGetSupplierQuery,
  useGetCountyQuery,
  useGetContainersTypeQuery,
  useGetServiceDataQuery,
  useGetWasteTypesQuery
} = supplierAPI;
