import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components/macro';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type TotalCardProps = {
  title: string;
  amount: number | string;
  circleColor: string;
  sx?: SxProps<Theme>;
};

type CircleProps = {
  circleColor: string;
};

const Circle = styled.div<CircleProps>`
  width: 19px;
  height: 19px;
  background-color: ${({ circleColor }) => circleColor};
  border-radius: 50%;
`;

export const TotalCard: React.FC<TotalCardProps> = ({
  title,
  amount,
  circleColor,
  sx
}): JSX.Element => (
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      height: '123px',
      backgroundColor: '#FEFFFF',
      boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.04)',
      borderRadius: '6px',
      padding: '25px',
      ...sx
    }}
  >
    <Stack direction="row" sx={{ height: '20px', alignItems: 'center', marginBottom: '15px' }}>
      <Circle circleColor={circleColor} />
      <Typography
        sx={{
          marginLeft: '8px',
          fontWeight: '500',
          fontSize: '16px',
          color: '#5E6C84'
        }}
      >
        {title}
      </Typography>
    </Stack>

    <Typography
      sx={{
        fontSize: '32px',
        fontWeight: 500,
        color: '#344563'
      }}
    >
      {amount}
    </Typography>
  </Box>
);
