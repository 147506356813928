import React from 'react';
import { Outlet } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components/macro';

import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { THEMES } from '../constants';
import createTheme from '../theme';

import GlobalStyle from '../components/GlobalStyle';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Presentation: React.FC = ({ children }) => {
  return (
    <MuiThemeProvider theme={createTheme(THEMES.LIGHT)}>
      <ThemeProvider theme={createTheme(THEMES.LIGHT)}>
        <Root>
          <CssBaseline />
          <GlobalStyle />
          <AppContent>
            <Header />
            {children}
            <Outlet />
            <Footer />
          </AppContent>
        </Root>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default Presentation;
