import React from 'react';
import { Link } from 'react-router-dom';
import { PATH_SUPPLIER } from '../constants';
import { Container, Typography, Button, Box, Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)(() => ({
  '&.MuiGrid-item': {
    maxWidth: '270px',
    width: '100%'
  }
}));

export const Main: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        Welcome to the Phoenix Waste Collection Console
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
        Please select one of the following...
      </Typography>
      <Box sx={{ mt: 5 }}>
        <Grid container spacing={8} direction="column" justifyContent="center" alignItems="center">
          <StyledGrid item>
            <Button
              component={Link}
              to={PATH_SUPPLIER.CALCULATOR}
              fullWidth
              size="large"
              type="button"
              variant="contained"
            >
              Create New Quote
            </Button>
          </StyledGrid>
          <StyledGrid item>
            <Button
              component={Link}
              to={PATH_SUPPLIER.SAVED_QUOTE}
              fullWidth
              size="large"
              type="button"
              variant="contained"
            >
              View Saved Quotes
            </Button>
          </StyledGrid>
          <StyledGrid item>
            <Button
              component={Link}
              to={PATH_SUPPLIER.SEARCH_SUPPLIER}
              fullWidth
              size="large"
              type="button"
              variant="contained"
            >
              Search Supplier by Zip code
            </Button>
          </StyledGrid>
          <StyledGrid item>
            <Button
              component={Link}
              to={PATH_SUPPLIER.DASHBOARD}
              fullWidth
              size="large"
              type="button"
              variant="contained"
            >
              Dashboard
            </Button>
          </StyledGrid>
          <StyledGrid item>
            <Button
              component={Link}
              to={PATH_SUPPLIER.SUMMARY}
              fullWidth
              size="large"
              type="button"
              variant="contained"
            >
              Summary
            </Button>
          </StyledGrid>
        </Grid>
      </Box>
    </Container>
  );
};
