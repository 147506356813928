import styled from 'styled-components/macro';
import { Radio, RadioGroup, FormControlLabel, FormControlLabelProps } from '@mui/material';

interface StyledLabelProps extends FormControlLabelProps {
  isChecked: boolean;
}

export const RootStyle = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 252px;
  height: 35px;
  border: 1px solid #e9edf0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  background-color: #fbfdff;
`;

export const StyledLabel = styled(FormControlLabel)<StyledLabelProps>`
  display: flex;
  justify-content: center;
  margin: 0;
  width: 33%;
  height: 25px;
  &:not(:last-of-type) {
    border-right: 1px solid #e9edf0;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme, isChecked }) => (isChecked ? '#cf342a' : theme.mainColor)};
  }
`;

export const StyledRadio = styled(Radio)`
  display: none;
`;
