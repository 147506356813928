import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const Title = styled(Typography)`
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #344563;
`;
