import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { calculatorAPI, resultAPI, dashboardAPI, supplierAPI } from '../services';
import chartSlice from './slices/QuotesSlice';
import confirmSlice from './slices/ConfirmSlice';
import freeBlockSlice from './slices/FreeBlockSlice';
import totalSlice from './slices/TotalSlice';
import totalWeightSlice from './slices/TotalWeight';

const rootReducer = combineReducers({
  [calculatorAPI.reducerPath]: calculatorAPI.reducer,
  [resultAPI.reducerPath]: resultAPI.reducer,
  [dashboardAPI.reducerPath]: dashboardAPI.reducer,
  [supplierAPI.reducerPath]: supplierAPI.reducer,
  chartSlice,
  confirmSlice,
  freeBlockSlice,
  totalSlice,
  totalWeightSlice
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        calculatorAPI.middleware,
        resultAPI.middleware,
        dashboardAPI.middleware,
        supplierAPI.middleware
      ])
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
