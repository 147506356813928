import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ResultCollectionComplete, ConfirmQuoteData, ServiceModelTypesData } from '../types/result';

enum CombineStream {
  No = 0,
  Yes = 1
}

export const resultAPI = createApi({
  reducerPath: 'resultAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['ResultCollection', 'ResultCollectionFilter', 'ConfirmQuote', 'ServiceModelTypes'],
  endpoints: (build) => ({
    fetchResultCollections: build.query<
      ResultCollectionComplete,
      {
        id: string;
        service_models?: any;
        sortBy?: string;
        combine_streams?: number;
        roofing: boolean;
      }
    >({
      query: ({ id, service_models, sortBy, combine_streams, roofing }) => ({
        url: `auth/quotes/${id}/find`,
        params: {
          service_models: service_models,
          sortBy: sortBy,
          combine_streams: combine_streams,
          roofing: roofing
        }
      }),
      keepUnusedDataFor: 0,
      providesTags: () => ['ResultCollection']
    }),
    fetchResultCollectionsFilter: build.query<
      ResultCollectionComplete,
      {
        id: string;
        limit?: number;
        offset?: any;
        waste_type_id?: number | string;
        service_models?: any;
        sortBy?: string;
        combine_streams?: number;
      }
    >({
      query: ({ id, limit, offset, waste_type_id, service_models, sortBy, combine_streams }) => ({
        url: `auth/quotes/${id}/find`,
        params: {
          waste_type_id: combine_streams === CombineStream.Yes ? [] : waste_type_id,
          limit: limit,
          offset: offset,
          service_models: service_models,
          sortBy: sortBy,
          combine_streams: combine_streams
        }
      }),
      keepUnusedDataFor: 0,
      providesTags: () => ['ResultCollectionFilter']
    }),
    createConfirmedQuote: build.mutation<ConfirmQuoteData, ConfirmQuoteData>({
      query: (collections) => ({
        url: `auth/quotes/${collections.id}/confirm`,
        method: 'POST',
        body: {
          ...collections.confirmed_collections,
          combined_streams_ids: collections.combined_streams_ids
        }
      }),
      invalidatesTags: ['ConfirmQuote']
    }),
    fetchServiceModel: build.query<ServiceModelTypesData, { limit?: number; search?: string }>({
      query: ({ limit, search }) => ({
        url: `/containers-types`,
        params: {
          limit: limit,
          search: search
        }
      }),
      providesTags: () => ['ServiceModelTypes']
    })
  })
});
