import React from 'react';
import styled from 'styled-components/macro';
import { Paper, Typography, Box, Link } from '@mui/material';

import VerifiedComponent from '../../components/auth/Verified';
import ConfirmVerifiedComponent from '../..//components/auth/ConfirmVerified';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const DEFAULT_STEP = 0;
const NEXT_STEP = 1;

const Verified: React.FC = () => {
  const [activeStep, setActiveStep] = React.useState(DEFAULT_STEP);

  const handleNext = () => {
    setActiveStep(NEXT_STEP);
  };

  const handleBack = () => {
    setActiveStep(DEFAULT_STEP);
  };

  return (
    <React.Fragment>
      {activeStep === DEFAULT_STEP ? (
        <Wrapper>
          <Typography variant="h3" paragraph>
            Account verified!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            2-factor authentication is required. Please set up 2-factor authentication below
          </Typography>
          <Box sx={{ mt: 5, mb: 3 }}>
            <VerifiedComponent handleNext={handleNext} />
          </Box>
        </Wrapper>
      ) : (
        <Wrapper>
          <Typography variant="h3" paragraph>
            Please enter the authentication code!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            We sent a 6-digit confirmation code, please enter the code in the box below to verify
            your account.
          </Typography>
          <Box sx={{ mt: 5, mb: 3 }}>
            <ConfirmVerifiedComponent />
          </Box>
          <Typography variant="body2" align="center">
            Don’t have a code? &nbsp;
            <Link
              variant="subtitle2"
              underline="none"
              onClick={handleBack}
              sx={{ cursor: 'pointer' }}
            >
              Back to account verified
            </Link>
          </Typography>
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export default Verified;
