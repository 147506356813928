import React from 'react';
import styled from 'styled-components/macro';
import { Main } from '../components/Main';
import { Box } from '@mui/material';

const RootStyle = styled(Box)({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center'
});

export const MainPage: React.FC = () => {
  return (
    <RootStyle sx={{ height: { sm: `calc(100vh - 126px)`, xs: `calc(100vh - 117px)` } }}>
      <Main />
    </RootStyle>
  );
};
