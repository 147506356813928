export const getErrorMsg = (error: any): string => {
  let message: string | any = 'Something went wrong!';

  if (error?.data?.error) {
    message = error?.data?.error;
  }
  if (error?.originalStatus === 401) {
    message = 'You are not authorized!';
  }

  return message;
};
