import React, { memo, useEffect } from 'react';
import styled, { withTheme, ThemeProps } from 'styled-components/macro';
import Chart from 'react-chartjs-2';
import {
  red,
  orange,
  green,
  deepPurple,
  lightGreen,
  teal,
  brown,
  lime
} from '@mui/material/colors';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Theme
} from '@mui/material';
import { spacing } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../hooks/useReduxHelper';
import { totalWeightSlice } from '../redux/slices/TotalWeight';

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

const ChartWrapper = styled.div`
  height: 275px;
  position: relative;
  margin: 20px 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding: 0;
`;
const TableCellData = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TableCellTotal = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
  &:first-child {
    padding-left: 0;
    padding-right: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }
  }
};

const PieChart = memo(({ theme }: ThemeProps<Theme>) => {
  const { totalWeight } = totalWeightSlice.actions;
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.chartSlice);

  const dataChart = {
    labels: data?.waste && Object.keys(data?.waste)?.map((el) => el),
    datasets: [
      {
        data: data?.waste && Object.keys(data?.waste)?.map((el) => data?.waste[el].percents),
        backgroundColor: [
          theme.palette.secondary.main,
          red[500],
          orange[500],
          green[500],
          teal[500],
          deepPurple[500],
          lightGreen[500],
          brown[500],
          lime[500]
        ],
        borderWidth: 5,
        borderColor: theme.palette.background.paper
      }
    ]
  };

  useEffect(() => {
    dispatch(totalWeight(Math.round(data?.total_weight_lbs?.toFixed(2))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.total_weight_lbs]);
  return (
    <Card mb={6}>
      <CardHeader title="Collection Summary" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Source</TableCell>
              <TableCell align="right">Weight</TableCell>
              <TableCell align="right">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.waste &&
              Object.keys(data?.waste).map((el, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {el}
                    </TableCell>
                    <TableCellData
                      title={`${data.waste[el].total_weight_lbs?.toFixed(2)} lbs`}
                      align="right"
                    >{`${data.waste[el].total_weight_lbs?.toFixed(2)} lbs`}</TableCellData>
                    <TableCellData
                      title={`${data.waste[el].percents?.toFixed(2)} %`}
                      align="right"
                    >{`${data.waste[el].percents?.toFixed(2) ?? 0} %`}</TableCellData>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <ChartWrapper>
          <Typography variant="h6">Pie Chart - Waste Types</Typography>
          <Chart type="pie" data={data?.waste && dataChart} options={options} />
        </ChartWrapper>
        <Table>
          <TableBody>
            <TableRow>
              <TableCellTotal component="th" scope="row">
                Total weight estimate:
              </TableCellTotal>
              <TableCellTotal align="right">
                {data?.waste && `${data?.total_weight_lbs?.toFixed(2)} lbs`}
              </TableCellTotal>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
});

export default withTheme(PieChart);
