import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Box,
  ButtonGroup,
  Stack
  // CircularProgress as MuiCircularProgress
} from '@mui/material';
// import { Blank } from '../components/result/Blank';
import { ConfirmTable } from '../components/ConfirmTable';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useGetCurrentSavedQuoteQuery, useExportQuoteQuery } from '../services';
import { useSnackbar } from 'notistack';
// import { useAppSelector } from '../hooks/useReduxHelper';
import { CircularProgress } from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import useLogOut from '../hooks/useLogOut';

const RootStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  padding: '20px 24px 0 24px'
}));
const options = ['Export in .pdf', 'Export in .xlsx', 'Export to email'];

export const Confirm: React.FC = () => {
  // USE HOOKS
  const [expData, setExpData] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  const { id = null } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: currentData,
    isLoading,
    error,
    isError
  } = useGetCurrentSavedQuoteQuery({ id }, { refetchOnMountOrArgChange: true });
  const {
    isSuccess: isSuccessExport,
    isLoading: exportLoading,
    error: errorExport,
    isError: isErrorExport
  } = useExportQuoteQuery(
    { id, ref: currentData?.quote?.ref, formatIndex: selectedIndex },
    { skip: !expData, refetchOnMountOrArgChange: true }
  );
  // const { free_block_result } = useAppSelector((state) => state.freeBlockSlice);
  // HANDLERS
  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401 || error?.status === 401) {
      setErrStatus(error?.status === 401 ? error?.status : error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };

  const handleClickExport = () => {
    setExpData(true);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(currentData?.quote?.ref);
    enqueueSnackbar('Copied', {
      variant: 'success'
    });
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };
  // HOOKS
  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
    if (!exportLoading) {
      setExpData(false);
    }
    if (isErrorExport) {
      errorHandler(errorExport);
    }
    if (isSuccessExport && selectedIndex === 2) {
      enqueueSnackbar('Email export successful, check your mail!', {
        variant: 'success'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, exportLoading, isErrorExport, isSuccessExport]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
    };
    return () => {
      // @ts-ignore
      window.onpopstate = undefined;
    };
  }, []);

  return (
    <RootStyle>
      <Grid container spacing={6}>
        <div>
          <Typography variant="h3" id="tableTitle" padding={'20px 24px 0 24px'}>
            Indicative Quote Confirmed!
          </Typography>
          <Stack display="flex" flexDirection="row" alignItems="baseline">
            <LabelStyle>Claim Number:</LabelStyle>
            <Typography variant="h6">{currentData?.quote?.claim_number}</Typography>
          </Stack>
        </div>
        <Grid item xs={12} zIndex={0}>
          <ConfirmTable rowsData={currentData} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          {/* <Blank {...free_block_result} /> */}
          <Box sx={{ mt: 2, mb: 5 }}>
            <Grid
              container
              spacing={5}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Button
                  title="Click for copy"
                  color="primary"
                  variant={'outlined'}
                  onClick={handleCopy}
                >
                  REF: {currentData?.quote?.ref}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 2, mb: 5 }}>
            <Grid
              container
              spacing={5}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                  <Button
                    disabled={exportLoading}
                    startIcon={exportLoading ? <CircularProgress size="0.9rem" /> : undefined}
                    onClick={handleClickExport}
                  >
                    {options[selectedIndex]}
                  </Button>
                  <Button
                    disabled={exportLoading}
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                      }}
                    >
                      <Paper sx={{ boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 8px', width: '145px' }}>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu">
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={(event) => handleMenuItemClick(event, index)}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </RootStyle>
  );
};
