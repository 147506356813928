import React, { memo, useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Grid, TextField as MuiTextField, FormHelperText } from '@mui/material';
import { spacing } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from 'notistack';
import { SetFieldType, ZipCodeData } from '../../types';
import useDebounce from '../../hooks/useDebounce';
import { useGetZipCodeQuery } from '../../services';
import useLogOut from '../../hooks/useLogOut';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import useObserver from '../../hooks/useObserver';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

type PropTypes = {
  isSubmitting: any;
  setFieldValue: any;
  getFieldProps: any;
  touched: any;
  errors: any;
  values: any;
  disableHelperTest?: boolean;
  errorProps?: FetchBaseQueryError | SerializedError | undefined;
};

const DURATION = 500;
const LIMIT = 20;

export const AutocompleteZipCode: React.FC<PropTypes> = memo(
  ({
    isSubmitting,
    setFieldValue,
    getFieldProps,
    touched,
    errors,
    values,
    disableHelperTest = false,
    errorProps
  }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [currentValue, setValueCurrentValue] = useState<any | null>(null);
    const debouncedValueZip = useDebounce<string>(inputValue, DURATION);
    const [limit, setLimit] = useState<number>(LIMIT);
    const { enqueueSnackbar } = useSnackbar();
    const [errStatus, setErrStatus] = useState(null);
    const { logOut } = useLogOut(errStatus);
    const {
      data: zipCodes = {
        data: []
      },
      error
    } = useGetZipCodeQuery(
      { search: debouncedValueZip, limit: limit },
      { refetchOnMountOrArgChange: true }
    );

    const zipCodeOptions: ZipCodeData[] = zipCodes?.data?.map((el: ZipCodeData) => {
      return {
        id: el.id,
        code: el.code
      };
    });
    const ref: any = useRef<HTMLUListElement>(null);
    const errorHandler = (error: any) => {
      let message: string | any = 'Something went wrong!';
      if (error?.data?.errors) {
        const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
        message = msg[0];
      }
      if (error?.data) {
        message = error?.data;
      }
      if (error?.originalStatus === 401) {
        setErrStatus(error?.originalStatus);
        message = 'You are not authorized!';
      }
      enqueueSnackbar(message, {
        variant: 'error'
      });
    };
    useEffect(() => {
      if (error || errorProps) {
        errorHandler(error || errorProps);
      }
      // eslint-disable-next-line
      }, [error, errorProps]);
    useEffect(() => {
      if (errStatus && typeof logOut === 'function') {
        logOut();
      }
    }, [errStatus, logOut]);

    const { pageNum, setLastElement } = useObserver();

    useEffect(() => {
      if (pageNum > 1) {
        setLimit(limit + 10);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNum]);

    const initValue = values?.zip_code ? { code: values.zip_code, id: values.zip_code_id } : null;
    return (
      <React.Fragment>
        <Grid item>
          {!disableHelperTest && <FormHelperText>Search</FormHelperText>}
          <Autocomplete
            disabled={isSubmitting}
            options={zipCodeOptions}
            getOptionLabel={(option) => `${option?.code}` || ''}
            value={currentValue ?? initValue}
            ListboxProps={{
              // There are type script warning but its worked fine
              ref: ref
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id} ref={setLastElement}>
                  {option.code}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={(event: any, newValue: SetFieldType) => {
              setValueCurrentValue(newValue);
              setFieldValue('zip_code_id', newValue?.id ?? '');
              setFieldValue('zip_code', newValue?.code ?? '');
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue as string);
              setFieldValue('zip_code', newInputValue ?? '');
              // setFieldValue('zip_code_id', null);
            }}
            id="controllable-states-demo"
            sx={{ width: 223 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Zip Code"
                variant="outlined"
                placeholder="Search and select zip code"
                fullWidth
                my={2}
                {...getFieldProps('zip_code')}
                error={Boolean(touched.zip_code && errors.zip_code)}
                helperText={touched.zip_code && errors.zip_code}
              />
            )}
          />
        </Grid>
      </React.Fragment>
    );
  }
);
