import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGetServiceDataQuery } from '../../services/SupplierServices';
import styled from 'styled-components/macro';

const StyledAccordionSummary = styled(AccordionSummary)({
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: '+1',
  borderBottom: '1px solid rgba(224,224,224,1)',
  minHeight: 'initial !important',
  padding: '5px 15px',
  '&>div': {
    margin: '0 !important'
  },
  '& svg': {
    height: 20
  }
});

const LIMIT = 50;

type ComponentProps = {
  name: string;
  id: string;
  supplierId: string;
  isDefaultOpened?: boolean;
};

export const SupplierServiceAccordion: React.FC<ComponentProps> = ({
  name,
  id,
  supplierId,
  isDefaultOpened
}) => {
  const [limit, setLimit] = useState(LIMIT);
  const [serviceId, setServiceId] = useState(isDefaultOpened ? id : '');
  const { data: collectionMethods, isLoading } = useGetServiceDataQuery(
    { supplierId, serviceId, limit },
    { skip: !supplierId || !serviceId }
  );

  const handleClick = () => setServiceId((prevState) => (prevState ? '' : `${id}`));

  const loadMoreHandle = () => setLimit((prev) => prev + LIMIT);

  return (
    <Accordion onChange={handleClick} defaultExpanded={isDefaultOpened}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2" component="span">
          {name}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        {isLoading && <CircularProgress />}
        <List>
          {collectionMethods?.data
            ?.slice()
            ?.sort((a, b) => a && a?.waste_type_name?.localeCompare(b?.waste_type_name))
            ?.map((v) => (
              <ListItem key={v?.id}>
                <ListItemText
                  primary={v?.waste_type_name}
                  secondary={
                    <>
                      <Typography component="span">{v?.container_name}: </Typography>
                      <Typography component="span" fontWeight="bold">
                        {v?.price_per_collection}$
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          {collectionMethods?.hasMore && (
            <ListItem sx={{ justifyContent: 'center' }}>
              <Button variant="outlined" onClick={loadMoreHandle}>
                Load more...
              </Button>
            </ListItem>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
