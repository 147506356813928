import * as React from 'react';
import {
  Box,
  Drawer,
  Toolbar,
  CssBaseline,
  List,
  Typography,
  IconButton,
  ListItem,
  Grid,
  styled,
  useTheme
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PieChart from './PieChart';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from 'react';

const drawerWidth = 320;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  matches?: boolean;
}>(
  ({ theme, open, matches }) =>
    !matches && {
      flexGrow: 1,
      width: '100%',
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      zIndex: theme.zIndex.drawer + 1,
      marginRight: -drawerWidth,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: 0
      })
    }
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

type DrawerType = {
  title: string;
  children: any;
};

export default function PersistentDrawerRight({ children, title }: DrawerType) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(matches);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const CalculatorNav = () => {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            position: 'inherit'
          }}
        >
          <Typography variant="h3" noWrap sx={{ flexGrow: 1 }} component="div">
            {title}
          </Typography>
          <IconButton
            title="Open sidebar"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{
              ...(open && { display: 'none' }),
              position: 'fixed',
              right: '35px',
              top: '75px',
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
      </Grid>
    );
  };

  useEffect(() => {
    if (matches) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [matches]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Main open={open} matches={matches}>
        {CalculatorNav()}
        {children}
      </Main>
      <Drawer
        sx={{
          width: !matches ? drawerWidth : 0,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth
          },
          zIndex: 1
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader />
        <List>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </List>
        <List>
          <ListItem>
            <PieChart />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
