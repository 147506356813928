import React, { ChangeEvent, MouseEvent, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Typography
} from '@mui/material';
import { spacing } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import faker from 'faker';
import { formatNumber } from '../utils/formatHelpers';

const Paper = styled(MuiPaper)(spacing);

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A200,
  height: 55
}));

const StyledTableRow = styled(TableRow)`
  background-color: #fff;
  :nth-child(2n) {
    background-color: ${({ theme }) => theme.palette.grey.A100};
  }
`;

type HeadCell = {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};
const headCells: Array<HeadCell> = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Supplier'
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Quote Confirm Date'
  },
  {
    id: 'collection_waste',
    numeric: true,
    disablePadding: false,
    label: 'Collection waste'
  },
  { id: 'total_volume', numeric: true, disablePadding: false, label: 'Total weight (lbs)' },
  { id: 'price', numeric: true, disablePadding: false, label: 'Price' },
  {
    id: 'claims_ref',
    numeric: true,
    disablePadding: true,
    label: 'Claims Reference'
  },
  { id: 'ref', numeric: true, disablePadding: false, label: 'Unique Ref. Number' }
];

const EnhancedTableHead: React.FC = () => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ padding: '6px 16px' }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};
type DataType = {
  data: any;
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (e: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isLoading: boolean;
  isFetching: boolean;
};
export const SummaryTable: React.FC<DataType> = ({
  data = [],
  page,
  rowsPerPage,
  count,
  onPageChange,
  onRowsPerPageChange,
  isLoading,
  isFetching
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const tableRef: any = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (!isLoading && !isFetching) {
      tableRef?.current?.scrollTo(0, 0);
    }
  }, [isLoading, isFetching]);
  return (
    <Box mb={10}>
      <Paper>
        <TableContainer sx={{ maxHeight: 640 }} ref={tableRef}>
          <Table
            sx={data?.length > 0 ? { minWidth: 800 } : { minWidth: 'auto' }}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {data?.length > 0 ? (
                <>
                  {data.map((row: any, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <StyledTableRow tabIndex={-1} key={row.ref}>
                        <TableCell
                          sx={{ maxWidth: '190px' }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                        >
                          {row.company_names.map((item: string) => (
                            <Typography key={faker.datatype.uuid()}>{item}</Typography>
                          ))}
                        </TableCell>
                        <TableCell
                          sx={{ minWidth: '160px', textAlign: 'center' }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                        >
                          {new Date(row.quote_confirm_date).toLocaleString('en-US', {
                            hour12: false
                          })}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                          align="center"
                        >
                          {row.collection_wastes.map((item: string) => (
                            <Typography key={faker.datatype.uuid()}>{item}</Typography>
                          ))}
                        </TableCell>
                        <TableCell align="center">{formatNumber(row.total_volume_lbs)}</TableCell>
                        <TableCell align="center">${formatNumber(row.total_cost)}</TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                          align="center"
                        >
                          {row.claims_ref || '-'}
                        </TableCell>
                        <TableCell align="center">{row.ref}</TableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                    <Paper>
                      <Typography gutterBottom align="center" variant="subtitle1">
                        There are no confirmed quotes yet
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data?.length > 0 && (
          <TablePagination
            labelRowsPerPage={matches ? 'Rows per page:' : ''}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        )}
      </Paper>
    </Box>
  );
};
