import React, { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { getWasteAndRecycleWasteData, WASTE_AND_RECYCLE_WASTE_OPTIONS } from './chartHelpers';
import { ChartCard } from './ChartCard';
import { StatisticsData } from '../../types';

type WasteAndRecycleWasteChartProps = {
  data: StatisticsData;
};

export const WasteAndRecycleWasteChart: React.FC<WasteAndRecycleWasteChartProps> = memo(
  ({ data }) => {
    return (
      <ChartCard title="Total Waste Produced & Recycled (tons)" sx={{ height: '302px' }}>
        <Bar
          height={302}
          options={WASTE_AND_RECYCLE_WASTE_OPTIONS}
          data={getWasteAndRecycleWasteData(data)}
        />
      </ChartCard>
    );
  }
);
