import styled from 'styled-components/macro';
import {
  CircularProgress as MuiCircularProgress,
  Select,
  Typography as TypographyDash
} from '@mui/material';
import { spacing } from '@mui/system';

export const CircularProgress = styled(MuiCircularProgress)(spacing);

export const RootStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fafafb;
  padding: 8px 160px 73px 171px;
`;

export const Typography = styled(TypographyDash)`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

export const Header = styled(TypographyDash)`
  font-size: 22px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  color: ${(props) => props.theme.header.color};
  text-align: left;
  margin-bottom: 8px;
`;

export const StyledSelect = styled(Select)`
  width: 103px;
  height: 35px;
  font-weight: 400;
  font-size: 14px !important;
  color: ${({ theme }) => theme.mainColor};
  border: 1px solid #e9edf0;
  background-color: #fbfdff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  & > div:first-of-type {
    overflow: initial !important;
    padding-left: 10px !important;
  }
`;

export const PickerWrapper = styled.div`
  position: relative;
`;

export const Image = styled.img`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%);
`;

export const PickerInput = styled.input`
  width: 241px;
  height: 35px;
  padding-left: 45px;
  color: rgba(52, 69, 99, 1);
  background-color: #fbfdff;
  border: 1px solid #e9edf0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  font-size: 14px;
  font-family: Rubik, sans-serif;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
`;
