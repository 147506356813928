import React from 'react';
import { RootStyle, StyledRadio, StyledLabel } from './RadioGroup.styled';

type RadioGroupProps = {
  value: number;
  onChange: (e: any) => void;
};

const RADIO_BUTTONS_CONFIG = [
  {
    id: 1,
    label: '3 Months',
    value: 2
  },
  {
    id: 2,
    label: '6 Months',
    value: 5
  },
  {
    id: 3,
    label: '12 Months',
    value: 11
  }
];

export const RadioGroup: React.FC<RadioGroupProps> = ({ value, onChange }): JSX.Element => {
  return (
    <RootStyle
      aria-labelledby="demo-radio-buttons-group-label"
      value={value}
      onChange={onChange}
      name="months"
    >
      {RADIO_BUTTONS_CONFIG.map(({ id, value: internalValue, label }) => (
        <StyledLabel
          isChecked={value === internalValue}
          key={id}
          value={internalValue}
          label={label}
          control={<StyledRadio />}
        />
      ))}
    </RootStyle>
  );
};
