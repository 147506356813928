import React, { Component, ErrorInfo, ReactNode } from 'react';
import axios from 'axios';

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  createdAt: string | null;
  side: number;
  hasError: boolean;
}
interface HasError {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null, createdAt: null, side: 1 };
  }

  static getDerivedStateFromError(error: Error): HasError {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    const dateTime = date + ' ' + time;

    axios
      .post(`${process.env.REACT_APP_API_URL}/log/create`, {
        err: error.toString(),
        error_info: errorInfo?.componentStack,
        err_time: dateTime,
        side: 1
      })
      .then((response) => console.log(response));

    this.setState({
      error: error,
      errorInfo: errorInfo,
      createdAt: new Date().toString()
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state?.error && this.state?.error.toString()}
            <br />
            {this.state?.errorInfo?.componentStack}
            <br />
            {this.state?.createdAt}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
