import { StatisticItem, StatisticsData } from '../../types';
import { format } from 'date-fns';
import { formatToK } from '../../utils/formatHelpers';

export const PAYOUT_CLAIMS_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false
    },
    legend: {
      display: false
    }
  },
  scales: {
    'first-dataset': {
      display: true,
      position: 'left',
      type: 'linear',
      barThickness: 10,
      grid: {
        drawOnChartArea: true,
        drawTicks: false,
        borderDash: [5, 5]
      },
      title: {
        display: false
      },
      ticks: {
        callback: function (value: string) {
          return '$' + formatToK(Number(value));
        },
        maxTicksLimit: 5
      }
    },
    'second-dataset': {
      display: true,
      type: 'linear',
      position: 'right',
      grid: {
        drawOnChartArea: false,
        display: false
      },
      ticks: {
        maxTicksLimit: 5
      },
      title: {
        display: false
      }
    },
    x: {
      grid: {
        display: false
      }
    }
  }
};

export const WASTE_AND_RECYCLE_WASTE_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: false
    },
    legend: {
      display: false
    }
  },
  scales: {
    'first-dataset': {
      display: true,
      position: 'left',
      type: 'linear',
      barThickness: 10,
      grid: {
        drawOnChartArea: true,
        drawTicks: false,
        borderDash: [5, 5]
      },
      title: {
        display: false
      },
      ticks: {
        callback: function (value: string) {
          return `${value}`;
        }
      }
    }
  }
};

export const getPluginDoughnut = (value: number) => ({
  id: 'doughnut',
  beforeDraw(chart: any) {
    const { width, height, ctx } = chart;

    ctx.restore();
    ctx.font = `bold 26px sans-serif`;
    ctx.fillStyle = 'rgba(52, 69, 99, 1)';
    ctx.textBaseline = 'top';
    ctx.color = 'black';

    const text: string = `${value.toFixed(1)}%`;
    const textX = Math.round((width - ctx.measureText(text).width) / 1.9);
    const textY = height / 2.5;

    ctx.fillText(text, textX, textY);
    ctx.save();
  }
});

export const DOUGHNUT_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    },
    tooltip: {
      enabled: false
    }
  },
  layout: {
    padding: {
      bottom: 5
    }
  },
  cutout: 45
};

const getMonthName = (item: StatisticItem): string => {
  return format(new Date(item.month_name), 'LLL');
};

const getLabels = (data: StatisticsData | undefined): Array<string> =>
  data ? data?.statistics.map((item) => getMonthName(item)) : [];

export const getPayoutAndClaimsData = (data: StatisticsData) => ({
  labels: getLabels(data),
  datasets: data
    ? [
        {
          label: 'Payout',
          data: data?.statistics.map((item) => item?.total_cost),
          backgroundColor: '#F2B3AF',
          borderColor: '#F2B3AF',
          yAxisID: 'first-dataset',
          order: 2,
          borderWidth: 1,
          borderRadius: 2
        },
        {
          label: 'Claims',
          data: data?.statistics.map((item) => item?.number_of_claims_processed),
          backgroundColor: '#CF342A',
          borderColor: '#CF342A',
          yAxisID: 'second-dataset',
          type: 'line',
          order: 1
        }
      ]
    : []
});

export const getWasteAndRecycleWasteData = (data: StatisticsData) => ({
  labels: getLabels(data),
  datasets: data
    ? [
        {
          label: 'Waste (tons)',
          data: data?.statistics.map((item) => item?.total_waste),
          backgroundColor: 'rgba(151, 151, 151, 1)',
          borderColor: 'rgba(151, 151, 151, 1)',
          yAxisID: 'first-dataset',
          borderWidth: 1,
          borderRadius: 2
        },
        {
          label: 'Waste recycled (tons)',
          data: data?.statistics.map((item) => item?.waste_diverted_from_landfill),
          backgroundColor: 'rgba(213, 213, 213, 1)',
          borderColor: 'rgba(213, 213, 213, 1)',
          yAxisID: 'first-dataset',
          borderWidth: 1,
          borderRadius: 2
        }
      ]
    : []
});

export const DOUGHNUT_WASTE_TYPES_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context: any) {
          return `${context.label} ${context.formattedValue}%`;
        }
      }
    },
    datalabels: {
      formatter: (value: number) => {
        return value + '%';
      },
      anchor: 'end',
      labels: {
        title: {
          font: {
            weight: '400',
            size: '12px'
          }
        },
        value: {
          color: 'rgba(52, 69, 99, 1)'
        }
      }
    }
  },
  layout: {
    padding: {
      bottom: 5
    }
  },
  cutout: 45
};

export const COLORS_ARRAY = [
  'rgba(101, 84, 192, 1)',
  'rgba(69, 184, 84, 1)',
  'rgba(97, 164, 228, 1)',
  'rgba(255, 169, 88, 1)',
  'rgba(216, 218, 118, 1)',
  'rgba(161, 165, 9, 1)',
  'rgba(91, 223, 108, 1)',
  'rgba(108, 255, 246, 1)',
  'rgba(244, 80, 90, 1)',
  'rgba(255, 132, 220, 1)',
  'rgba(207, 52, 42, 1)',
  'rgba(242, 179, 175, 1)'
];
