import React, { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { getPayoutAndClaimsData, PAYOUT_CLAIMS_CHART_OPTIONS } from './chartHelpers';
import { ChartCard } from './ChartCard';
import { StatisticsData } from '../../types';

type PayoutAndClaimsChartProps = {
  data: StatisticsData;
};

export const PayoutAndClaimsChart: React.FC<PayoutAndClaimsChartProps> = memo(({ data }) => {
  return (
    <ChartCard title="Total Payout & Number of Claims" sx={{ height: '302px' }}>
      <Bar height={302} options={PAYOUT_CLAIMS_CHART_OPTIONS} data={getPayoutAndClaimsData(data)} />
    </ChartCard>
  );
});
