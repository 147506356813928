import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Container, Grid, Box, MenuItem, SelectChangeEvent, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  format,
  endOfDay,
  subMonths,
  isValid,
  differenceInYears,
  addYears,
  startOfMonth
} from 'date-fns';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js';
import useAuth from '../../hooks/useAuth';
import useLogOut from '../../hooks/useLogOut';
import { useGetStatisticsDataQuery } from '../../services/DashboardServices';
import { TotalCard } from '../../components/TotalCard';
import axios from '../../utils/axios';
import { downloadExcelHelper } from '../../utils/download';
import {
  RootStyle,
  Header,
  CircularProgress,
  StyledSelect,
  Typography,
  PickerInput,
  PickerWrapper,
  Image
} from './Dashboard.styled';
import { RadioGroup } from './RadioGroup';
import { formatNumber } from '../../utils/formatHelpers';
import { DateImg, Export } from '../../assets';
import { PayoutAndClaimsChart } from './PayoutAndClaimsChart';
import { WasteAndRecycleWasteChart } from './WasteAndRecycleWasteChart';
import { AverageRecyclingRatesChart } from './AverageRecyclingRatesChart';
import { WasteTypesChart } from './WasteTypesChart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CLAIM_OPTIONS = [
  {
    value: 1,
    label: 'All claims'
  },
  {
    value: 0,
    label: 'My claims'
  }
];

const formatDate = (date: Date): string => format(endOfDay(date), 'yyyy-MM-dd');
const isValidDate = (date: Date | string | null): boolean => isValid(date);
const COMMON_PICKER_DATE_VIEW = 'dd,MMM,yyyy';

export const Dashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [errStatus, setErrStatus] = useState(null);
  const [showAll, setShowAll] = useState<number>(CLAIM_OPTIONS[0].value);
  const [dateFrom, setDateFrom] = useState<string>(
    formatDate(startOfMonth(subMonths(new Date(), 2)))
  );
  const [dateTo, setDateTo] = useState<string>(formatDate(new Date()));
  const [radioValue, setRadioValue] = useState<number>(2); //3 months
  const { user } = useAuth();
  const { logOut } = useLogOut(errStatus);
  const { data, isError, error } = useGetStatisticsDataQuery(
    { showAll: user?.is_quote_viewer ? !!showAll : false, dateTo, dateFrom },
    { refetchOnMountOrArgChange: true, skip: !user }
  );

  const handleChange = (e: SelectChangeEvent<unknown>, child: ReactNode): void => {
    const { value } = e.target;
    setShowAll(Number(value));
  };

  const handleChangeRadio = useCallback((e: any) => {
    setRadioValue(Number(e.target.value));
    setDateFrom(formatDate(startOfMonth(subMonths(new Date(), e.target.value))));
    setDateTo(formatDate(new Date()));
  }, []);

  const handleExportData = async () => {
    try {
      const res = await axios.get('/auth/quotes/dashboard-statistics/excel', {
        params: {
          showAll: !!showAll,
          from: dateFrom,
          to: dateTo
        },
        responseType: 'blob'
      });
      downloadExcelHelper(res.data);
    } catch (e: any) {
      enqueueSnackbar(e?.message, {
        variant: 'error'
      });
    }
  };

  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
    // eslint-disable-next-line
  }, [error]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);

  return (
    <RootStyle>
      <Container maxWidth="md">
        {data && user ? (
          <>
            <Header>Waste Collection Dashboard</Header>
            <Grid container rowSpacing={2} columnSpacing={2} columns={12}>
              <Grid
                item
                lg={8}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: 0
                }}
              >
                {user?.is_quote_viewer ? (
                  <StyledSelect value={showAll} onChange={handleChange}>
                    {CLAIM_OPTIONS.map(({ label, value }) => (
                      <MenuItem key={`claim-option-${value}`} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                ) : (
                  <></>
                )}
                <RadioGroup value={radioValue} onChange={handleChangeRadio} />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    value={[dateFrom, dateTo]}
                    maxDate={new Date()}
                    inputFormat={COMMON_PICKER_DATE_VIEW}
                    onChange={(dates) => {
                      if (dates[0] && dates[1] && isValidDate(dates[0]) && isValidDate(dates[1])) {
                        if (differenceInYears(new Date(dates[1]), new Date(dates[0]))) {
                          setDateFrom(formatDate(new Date(dates[0])));
                          setDateTo(formatDate(addYears(new Date(dates[0]), 1)));
                          enqueueSnackbar(
                            'The graph can show up to a year of data. Please select up to 12 months',
                            {
                              variant: 'info'
                            }
                          );
                        } else {
                          setDateFrom(formatDate(new Date(dates[0])));
                          setDateTo(formatDate(new Date(dates[1])));
                        }
                        setRadioValue(-1);
                      }
                    }}
                    renderInput={(startProps, endProps) => (
                      <PickerWrapper>
                        <Image src={DateImg} alt="date_image" />
                        <PickerInput
                          disabled
                          type="text"
                          {...startProps.inputProps}
                          ref={startProps.inputRef as React.Ref<HTMLInputElement>}
                          value={`${startProps?.inputProps?.value} - ${endProps?.inputProps?.value}`}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </PickerWrapper>
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  onClick={handleExportData}
                  type="button"
                  variant="contained"
                  value="search"
                  sx={{
                    width: '141px',
                    height: '35px',
                    backgroundColor: '#CF342A',
                    padding: '8px 16px',
                    fontSize: '14px',
                    '&:hover': {
                      backgroundColor: '#CF342A',
                      opacity: '0.8'
                    }
                  }}
                >
                  <img style={{ marginRight: '8px' }} src={Export} alt="export" />
                  Export data
                </Button>
              </Grid>
              <Grid item lg={8}>
                <PayoutAndClaimsChart data={data} />
              </Grid>
              <Grid item lg={4}>
                <TotalCard
                  title="Total payout"
                  amount={`$${formatNumber(data?.total.total_cost || 0)}`}
                  circleColor="#F2B3AF"
                  sx={{ marginBottom: '12px' }}
                />
                <TotalCard
                  title="Total claims"
                  amount={data?.total.number_of_claims_processed || 0}
                  circleColor="#CF342A"
                />
              </Grid>
              <Grid item lg={8}>
                <WasteAndRecycleWasteChart data={data} />
              </Grid>
              <Grid item lg={4}>
                <TotalCard
                  title="Total waste"
                  amount={`${formatNumber(data?.total.total_waste || 0)} tons`}
                  circleColor="#979797"
                  sx={{ marginBottom: '12px' }}
                />
                <TotalCard
                  title="Total recycled waste"
                  amount={`${formatNumber(data?.total.waste_diverted_from_landfill || 0)} tons`}
                  circleColor="#D5D5D5"
                />
              </Grid>
              <Grid item lg={4}>
                <AverageRecyclingRatesChart
                  averageRecyclingRate={data?.total.average_recycling_rate}
                />
              </Grid>
              <Grid item lg={8}>
                <WasteTypesChart data={data} />
              </Grid>
            </Grid>
          </>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center">
            {isError ? <Typography>Something went wrong!</Typography> : <CircularProgress m={2} />}
          </Box>
        )}
      </Container>
    </RootStyle>
  );
};
