import React, { memo } from 'react';
import styled from 'styled-components/macro';
import Drawer from '../components/Drawer';
import { CollectionForm } from '../components/calculator/CalculationForm';

export const Calculator: React.FC = memo(() => {
  const RootStyle = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  `;

  return (
    <RootStyle>
      <Drawer title={'Calculate Collection'}>
        <CollectionForm />
      </Drawer>
    </RootStyle>
  );
});
