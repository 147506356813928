import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Grid,
  Typography,
  Button,
  Box,
  Stack,
  CircularProgress as MuiCircularProgress
} from '@mui/material';
import { ResultTable } from '../components/result/ResultTable';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { resultAPI } from '../services';
import { useAppSelector, useAppDispatch } from '../hooks/useReduxHelper';
import { clearItems } from '../redux/slices/ConfirmSlice';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { spacing } from '@mui/system';
import useErrorHandler from '../hooks/useErrorHandler';

const CircularProgress = styled(MuiCircularProgress)(spacing);

const RootStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.text.secondary,
  paddingRight: '5px'
}));

const ButtonsName = [
  { name: 'Best overall', sort: 'overall' },
  { name: 'Lowest cost', sort: 'price' },
  { name: 'Most sustainable', sort: 'recycling' }
];
const MAX_LBS = 1000;
const MAX_VOLUME_CF = 70;
const DROP_OFF = 'Customer drop-off';
const CONTAINER_NAME_DROP_OFF = 'Drop-Off';

const ROOFING_ID = '12';

export const Result: React.FC = () => {
  // USE HOOKS
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { errorHandler } = useErrorHandler();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const { id } = useParams();
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const [sortBy, setSortBy] = useState<string>('overall');
  const [isValidVolume, setIsValidVolume] = useState(true);
  const [isRoofing, setIsRoofing] = useState(false);
  const [serviceModelEnabled, setServiceModelEnabled] = useState<number[]>([]);
  const [checkBox, setCheckBox] = React.useState({
    dropOff: true,
    courier: true,
    roofing: true
  });
  const [isOneContainer, setIsOneContainer] = useState(false);
  const [isCustomerDropOff, setIsCustomerDropOff] = useState(false);
  const checkCustomerDropOff = useRef(true);

  const [createConfirmedQuote, { isSuccess, error, isError, isLoading }] =
    resultAPI.useCreateConfirmedQuoteMutation();

  const confirmed_collections = useAppSelector((state) => state.confirmSlice);

  const {
    data: serviceModel = { data: [] },
    // isLoading: isFetchingService,
    isError: isErrorService,
    error: errorService
  } = resultAPI.useFetchServiceModelQuery({});

  const { dropOff, courier, roofing } = checkBox;

  const {
    data: resultData = { claim_number: '', result: [] },
    isFetching: isLoadingResult,
    // isLoading: isLoadingRes,
    isError: isErrorResult,
    error: errorResult
  } = resultAPI.useFetchResultCollectionsQuery(
    {
      id,
      service_models: JSON.stringify(serviceModelEnabled),
      sortBy,
      combine_streams: +isOneContainer,
      roofing: roofing
    },
    { refetchOnMountOrArgChange: true, skip: !!!serviceModelEnabled?.length }
  );

  useEffect(() => {
    if (checkCustomerDropOff.current && resultData.result.length > 0) {
      checkCustomerDropOff.current = false;
      const result = resultData.result.some((item) =>
        item?.recommended_supplier_collection.data.find(
          (supplier) => supplier?.collection_info?.service_model_name === DROP_OFF
        )
      );
      setIsCustomerDropOff(result);
    }
  }, [resultData]);

  // HANDLERS
  const handleConfirm = () => {
    const collections: any = {
      confirmed_collections,
      id,
      combined_streams_ids:
        resultData?.result[0]?.waste_type_names && resultData?.result[0]?.waste_type_id
    };
    createConfirmedQuote(collections);
  };
  const buttonResultHandler = useCallback((sort: string) => {
    setSortBy(sort);
    setIsOneContainer(false);
  }, []);

  const checkboxFunc = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (!e.target.checked) {
      setServiceModelEnabled(serviceModelEnabled.filter((item) => item !== id));
    } else {
      setServiceModelEnabled(
        !serviceModelEnabled.includes(id) ? [...serviceModelEnabled, id] : serviceModelEnabled
      );
    }
  };
  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBox({
      ...checkBox,
      [event.target.name]: event.target.checked
    });
    if (event.target.name === 'dropOff') {
      const id = serviceModel?.data?.find((item) => item?.name === 'Customer drop-off')?.id;
      checkboxFunc(event, id);
    }

    if (event.target.name === 'courier') {
      const id = serviceModel?.data?.find((item) => item?.name === 'Courier/ Postal')?.id;
      checkboxFunc(event, id);
    }
    if (event.target.name === 'roofing') {
      checkboxFunc(event, id);
    }
  };

  // HOOKS
  useEffect(() => {
    if (isSuccess) {
      navigate(`/calculator/${id}/confirm`);
      dispatch(clearItems());
    }
    if (isError) {
      errorHandler(error);
    }
    if (isErrorResult) {
      errorHandler(errorResult);
    }
    if (isErrorService) {
      errorHandler(errorService);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError, error, isErrorResult, errorResult, isErrorService, errorService]);

  useEffect(() => {
    if (resultData) {
      if (resultData?.result?.length) {
        const totalVolume = resultData?.result?.map(
          (el) =>
            el.total_weight_lbs < MAX_LBS ||
            el.total_volume_cf < MAX_VOLUME_CF ||
            el.recommended_supplier_collection?.data[0]?.collection_info.container_name ===
              CONTAINER_NAME_DROP_OFF
        );

        setIsValidVolume(totalVolume.includes(true));
      }
      const isEmptyResult = Object.values(resultData.result)?.find(
        (item: any) => item?.recommended_supplier_collection?.data?.length === 0
      );
      if (!isEmptyResult) {
        setIsDisabledBtn(false);
      }
      if (isEmptyResult) {
        setIsDisabledBtn(true);
        const message = 'You cannot confirm this quote!';
        errorHandler(message, 'warning');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultData, setIsValidVolume]);
  useEffect(() => {
    const modelIds = serviceModel?.data?.map(({ id }) => id);
    if (isValidVolume) {
      setServiceModelEnabled(modelIds);
    } else {
      const id = serviceModel?.data?.find((item) => item?.name === DROP_OFF)?.id;
      setServiceModelEnabled(modelIds.filter((item) => item !== id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceModel, isValidVolume]);

  const containersLimitMessage: string | undefined = resultData?.result?.find(
    (row) => row?.recommended_supplier_collection?.data[0]?.message
  )?.recommended_supplier_collection?.data[0]?.message;

  useEffect(() => {
    if (!!containersLimitMessage) {
      errorHandler(containersLimitMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containersLimitMessage, resultData?.result]);

  useEffect(() => {
    if (roofing) {
      const isContainedRoofing = !!resultData?.result.find((el) =>
        el.waste_type_id.includes(ROOFING_ID)
      );
      setIsRoofing(isContainedRoofing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultData?.result]);

  return (
    <RootStyle>
      <Grid container spacing={8}>
        <Box flexDirection="column" padding={'0 24px 0 24px'} width="100%">
          <Box flexDirection="column">
            <div>
              <Typography variant="h3" id="tableTitle">
                Here's your results...
              </Typography>
            </div>
          </Box>
          <Grid
            mt={4}
            container
            flexDirection={isSm ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems={isSm ? 'flex-start' : 'center'}
            flexWrap="nowrap"
          >
            <Grid container spacing={4}>
              <Grid item>
                {resultData?.claim_number && (
                  <Stack
                    spacing={3}
                    display="flex"
                    flexDirection="row"
                    alignItems="baseline"
                    sx={{ pb: 5 }}
                  >
                    <LabelStyle>Claim Number:</LabelStyle>
                    <Typography variant="h4">{resultData?.claim_number}</Typography>
                  </Stack>
                )}
              </Grid>
              <Grid item container spacing={4}>
                {ButtonsName.map(({ name, sort }) => (
                  <Grid item key={sort}>
                    <Button
                      variant={sortBy === sort && !isOneContainer ? 'contained' : 'outlined'}
                      size="large"
                      onClick={() => buttonResultHandler(sort)}
                      // disabled={sort === 'recycling' && checkBox.combineWaste}
                    >
                      {name}
                    </Button>
                  </Grid>
                ))}
                <Grid item>
                  <Button
                    variant={isOneContainer ? 'contained' : 'outlined'}
                    size="large"
                    onClick={() => setIsOneContainer(true)}
                    disabled={
                      (resultData?.result?.length === 1 &&
                        !resultData?.result[0]?.waste_type_names) ||
                      !resultData?.result.length
                    }
                  >
                    General collection
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" fullWidth>
                <FormLabel component="legend" focused={false}>
                  Include results for...
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    disabled={!isValidVolume || !isCustomerDropOff}
                    control={
                      <Checkbox
                        checked={isValidVolume && isCustomerDropOff ? dropOff : false}
                        onChange={handleChangeCheckBox}
                        name="dropOff"
                      />
                    }
                    label="Customer drop-off"
                  />
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Checkbox checked={courier} onChange={handleChangeCheckBox} name="courier" />
                    }
                    label="Courier/Postal"
                  />
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Checkbox
                        disabled={!isRoofing}
                        checked={isRoofing ? roofing : false}
                        onChange={handleChangeCheckBox}
                        name="roofing"
                      />
                    }
                    label="Roofing"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12}>
          <ResultTable
            isGeneralCollectionError={isErrorResult && isOneContainer}
            resultData={resultData}
            serviceModelEnabled={serviceModelEnabled}
            isLoading={isLoadingResult}
            sortBy={sortBy}
            combineStreams={+isOneContainer}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <Blank {...free_block_result} /> */}
          <Box sx={{ mt: 2, mb: 5 }}>
            <Grid
              container
              spacing={5}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={handleConfirm}
                  disabled={
                    isLoading ||
                    isDisabledBtn ||
                    isErrorResult ||
                    isLoadingResult ||
                    !!containersLimitMessage ||
                    !resultData?.result.length
                  }
                  startIcon={isLoading ? <CircularProgress size="0.9rem" /> : undefined}
                  color="primary"
                  variant={'contained'}
                >
                  Finalize this quote
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </RootStyle>
  );
};
