import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Drawer as MuiDrawer, ListItemButton } from '@mui/material';

import SidebarNav from './SidebarNav';
import { Logo } from '../../assets';

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: #fff;
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: start;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: #fff;
  }
`;

const LogoBrand = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  color: ${(props) => props.theme.header.color};
`;

const LogoBrandContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 110px;
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: 'permanent' | 'persistent' | 'temporary';
  open?: boolean;
  onClose?: () => void;
  showFooter?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({ showFooter = true, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/">
        <LogoBrandContainer>
          <img src={Logo} alt="Logo" />
          <LogoBrand>Phoenix</LogoBrand>
        </LogoBrandContainer>
      </Brand>
      <SidebarNav />
    </Drawer>
  );
};

export default Sidebar;
