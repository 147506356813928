import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { CssBaseline } from '@mui/material';
import GlobalStyle from '../GlobalStyle';
import useAuth from '../../hooks/useAuth';
import SignIn from '../../pages/auth/SignIn';

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;
interface AuthGuardType {
  children: ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated } = useAuth();
  const accessToken = window.localStorage.getItem('accessToken');
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  if (!isAuthenticated && !accessToken) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return (
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <SignIn />
      </Root>
    );
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

export default AuthGuard;
