import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Typography
} from '@mui/material';
import { Delete as DeleteIcon, RemoveRedEye } from '@mui/icons-material';
import { spacing } from '@mui/system';
import * as Icon from 'react-feather';
import { useDeleteQuotesMutation } from '../services';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useLogOut from '../hooks/useLogOut';
import useAuth from '../hooks/useAuth';
import { getErrorMsg } from '../utils/getErrorMsg';

type RowTypeItem = {
  description?: string;
  quantity?: number;
  waste_item_id?: number;
};

type HeadCell = {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};

type MyQuoteTableProps = {
  quotes: any[];
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (e: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const Paper = styled(MuiPaper)(spacing);

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  height: 55
}));

const headCells: Array<HeadCell> = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Date/Time Saved'
  },
  { id: 'claim_number', numeric: true, disablePadding: false, label: 'Claim Number' },
  { id: 'location', numeric: true, disablePadding: false, label: 'Collection Location' },
  { id: 'waste', numeric: true, disablePadding: false, label: 'Collection Waste' },
  { id: 'ref', numeric: true, disablePadding: false, label: 'Unique Ref. Number' },
  { id: 'button', numeric: true, disablePadding: false, label: '' }
];

const QUOTE_VIEWER_ROW: HeadCell = {
  id: 'email',
  numeric: false,
  disablePadding: true,
  label: 'Created by'
};

const EnhancedTableHead: React.FC = () => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ padding: '6px 16px' }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

export const MyQuoteTable: React.FC<MyQuoteTableProps> = ({
  quotes,
  page,
  rowsPerPage,
  count,
  onPageChange,
  onRowsPerPageChange
}) => {
  const [
    deleteQuotes,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete, error: errorDelete }
  ]: any = useDeleteQuotesMutation();

  const [dialog, setDialog] = useState<{ id: number; isOpen: boolean } | null>(null);
  const [errStatus, setErrStatus] = useState(null);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const { user } = useAuth();
  const { logOut } = useLogOut(errStatus);
  const { enqueueSnackbar } = useSnackbar();

  const isShowOwner = Boolean(user && user.is_quote_viewer);
  const isViewerRowAdded = headCells.includes(QUOTE_VIEWER_ROW);

  const handleDelete = () => {
    if (dialog?.id) {
      deleteQuotes({ id: dialog?.id });
      handleClose();
    }
  };
  const handleClose = () => setDialog(null);
  const handleOpen = (id: number) => setDialog({ id, isOpen: true });
  const errorHandler = (error: any) => {
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
    }
    enqueueSnackbar(getErrorMsg(error), { variant: 'error' });
  };

  useEffect(() => {
    if (isSuccessDelete) {
      enqueueSnackbar('Deleted successfully', {
        variant: 'success'
      });
    }
    if (errorDelete) {
      errorHandler(errorDelete);
    }
    // eslint-disable-next-line
    }, [isSuccessDelete, errorDelete]);
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);

  if (isShowOwner && !isViewerRowAdded) {
    headCells.unshift(QUOTE_VIEWER_ROW);
  }

  return (
    <Paper sx={{ marginBottom: 10 }}>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table
          sx={{ minWidth: 800 }}
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
        >
          <EnhancedTableHead />
          <TableBody>
            {quotes?.map((row: any, index: number) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow tabIndex={-1} key={row.id}>
                  {isShowOwner && (
                    <TableCell id={labelId} align="left">
                      {row?.email}
                    </TableCell>
                  )}
                  <TableCell component="th" id={labelId} scope="row" padding="normal">
                    {new Date(row.updated_at).toLocaleString('en-US', {
                      hour12: false
                    })}
                  </TableCell>
                  <TableCell align="center">{row?.claim_number}</TableCell>
                  <TableCell align="center">{row.zip_code}</TableCell>
                  <TableCell align="center">
                    {row.items.map((item: RowTypeItem) => item.description).join('. ')}
                  </TableCell>
                  <TableCell align="center">{row.ref}</TableCell>
                  <TableCell align="right">
                    {
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {row.ref && (
                          <IconButton
                            aria-label="Review"
                            size="large"
                            title="View current quote"
                            onClick={() => navigate(`/calculator/${row.id}/confirm`)}
                          >
                            <RemoveRedEye />
                          </IconButton>
                        )}
                        <IconButton
                          aria-label="Edit"
                          size="large"
                          title={!row.ref ? 'Edit current quote' : ''}
                          sx={!row.ref ? { cursor: 'pointer' } : {}}
                          onClick={() => navigate(`/calculator/${row.id}`)}
                        >
                          <Icon.Edit />
                        </IconButton>
                        <IconButton
                          aria-label="Delete"
                          size="large"
                          title="Delete current quote"
                          onClick={() => handleOpen(row.id)}
                          disabled={row.id === dialog?.id && isLoadingDelete}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {quotes?.length ? (
        <TablePagination
          labelRowsPerPage={!!matches ? 'Rows per page:' : ''}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      ) : (
        <Box py={5}>
          <Typography gutterBottom align="center" variant="subtitle1">
            There are no saved quotes yet
          </Typography>
        </Box>
      )}

      <Dialog open={!!dialog?.isOpen} onClose={handleClose} aria-labelledby="dialog-title">
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
