import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useSnackbar, VariantType } from 'notistack';
import { getErrorMessage } from 'helpers/errorHelpers';
import { useEffect, useState } from 'react';
import useLogOut from './useLogOut';

const NOT_AUTHORIZED_MESSAGE = 'You are not authorized!';

const useErrorHandler = () => {
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  const { enqueueSnackbar } = useSnackbar();
  const errorHandler = (
    error: FetchBaseQueryError | SerializedError | undefined | string,
    variant?: VariantType
  ) => {
    const { originalStatus }: any = error;

    if (originalStatus === 401) {
      setErrStatus(originalStatus);
      enqueueSnackbar(NOT_AUTHORIZED_MESSAGE, {
        variant: 'error'
      });
    }
    enqueueSnackbar(getErrorMessage(error), { variant: variant ?? 'error' });
  };

  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);

  return {
    errorHandler
  };
};

export default useErrorHandler;
