import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import PresentationLayout from './layouts/Presentation';
import DashboardLayout from './layouts/Dashboard';

// Guards
import AuthGuard from './components/guards/AuthGuard';
import GuestGuard from './components/guards/GuestGuard';
// Auth components
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import ResetPassword from './pages/auth/ResetPassword';
import ForgotPassword from './pages/auth/ForgotPassword';
import Verified from './pages/auth/Verified';
import ConfirmEmail from './pages/auth/ConfirmEmail';
import Page404 from './pages/auth/Page404';
import Page500 from './pages/auth/Page500';
// Pages
import { MainPage } from './pages/MainPage';
import { Calculator } from './pages/Calculator';
import { Confirm } from './pages/Confirm';
import { Result } from './pages/Result';
import { MyQuote } from './pages/MyQuote';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { SearchSupplier } from './pages/SearchSupplier';
import { SupplierPage } from './pages/SupplierPage';
import { Code } from './pages/auth/Code';
import { Summary } from './pages/Summary';

function Router() {
  return useRoutes([
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'sign-in',
          element: <SignIn />
        },
        {
          path: 'sign-up',
          element: <SignUp />
        },
        {
          path: 'confirm-email',
          element: <ConfirmEmail />
        },
        {
          path: 'reset-password',
          element: <ResetPassword />
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />
        },
        {
          path: '2fa',
          element: <Verified />
        },
        {
          path: '2fa/code',
          element: <Code />
        }
      ]
    },
    {
      path: '*',
      element: <AuthLayout />,
      children: [
        {
          path: '404',
          element: <Page404 />
        },
        {
          path: '500',
          element: <Page500 />
        },
        {
          path: '*',
          element: <Navigate to="/404" replace />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <PresentationLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <MainPage /> },
        { path: 'my-quote', element: <MyQuote /> },
        { path: 'search-supplier', element: <SearchSupplier /> },
        { path: 'search-supplier/:id', element: <SearchSupplier /> },
        { path: 'search-supplier/:id/supplier/:supplierId', element: <SupplierPage /> },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'summary', element: <Summary /> }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'calculator', element: <Calculator /> },
        { path: 'calculator/:id', element: <Calculator /> },
        { path: 'calculator/:id/result:totalWeight', element: <Result /> },
        { path: 'calculator/:id/confirm', element: <Confirm /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

export default Router;
