export const downloadExcelHelper = (data: BlobPart): void => {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const urlToBlob = URL.createObjectURL(blob);
  const hiddenElement = document.createElement('a');
  hiddenElement.href = urlToBlob;
  hiddenElement.target = '_blank';
  hiddenElement.download = 'dashboard-statistics.xlsx';
  hiddenElement.click();
};
