import React from 'react';
import { Title } from './ChartCard.styled';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

type ChartCardProps = {
  title: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
};

export const ChartCard: React.FC<ChartCardProps> = ({ title, sx, children }): JSX.Element => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        boxShadow: '0 25px 50px rgba(0, 0, 0, 0.04)',
        borderRadius: '6px',
        padding: '10px 20px 45px 25px',
        ...sx
      }}
    >
      <Title>{title}</Title>
      {children}
    </Box>
  );
};
