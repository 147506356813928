import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FreeBlockData } from '../../types/result';

const LBS_IN_TON = 2000;
const HUNDRED_PERCENTS = 100;
const WASTE_PRICE_PER_TON = 240;
const NOT_RECYCLABLE_WASTE_TYPES = ['Hazardous Waste', 'General Solid Waste/ Donations'];

const initialState: FreeBlockData = {
  free_block_result: {},
  saved_data: []
};

export const freeBlockSlice = createSlice({
  name: 'freeBlock',
  initialState,
  reducers: {
    saveItems: (state, action: PayloadAction<any>) => {
      state.saved_data = Array.isArray(action.payload)
        ? action.payload
        : [...state.saved_data, action.payload];
    },
    updateSavedItems: (state, action: PayloadAction<any>) => {
      state.saved_data = state.saved_data.map((item: any) =>
        item.total_weight_lbs === action.payload.total_weight_lbs
          ? {
              ...item,
              waste_type_name: action.payload.waste_type_name,
              price_per_collection: action.payload.price_per_collection,
              free_of_charge: action.payload.free_of_charge
            }
          : item
      );
    },
    calculateFreeBlock: (state, action: PayloadAction<any>) => {
      const res: any = { freeCollection: [], recycledPercents: 0, saving: 0 };
      let totalWasteWeight = 0;
      let recycledWasteWeigh = 0;
      let totalPaidPrice = 0;

      action.payload.forEach((element: any) => {
        totalWasteWeight += element.total_weight_lbs;
        if (!NOT_RECYCLABLE_WASTE_TYPES.includes(element.waste_type_name)) {
          recycledWasteWeigh += element.total_weight_lbs;
        }
        if (!element.free_of_charge && element.price_per_collection) {
          totalPaidPrice += element.price_per_collection;
        } else {
          res.freeCollection.push(element.waste_type_name);
        }
      });
      res.recycledPercents = ((HUNDRED_PERCENTS / totalWasteWeight) * recycledWasteWeigh).toFixed(
        1
      );
      const save = WASTE_PRICE_PER_TON * (totalWasteWeight / LBS_IN_TON) - totalPaidPrice;
      res.saving = Math.abs(save) === save ? save.toFixed(2) : 0;
      state.free_block_result = res;
    },
    clearResult: (state) => {
      state.free_block_result = initialState.free_block_result;
      state.saved_data = initialState.saved_data;
    }
  }
});
export const { calculateFreeBlock, clearResult, saveItems, updateSavedItems } =
  freeBlockSlice.actions;

export default freeBlockSlice.reducer;
