import React, { memo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import styled from 'styled-components/macro';
import { DOUGHNUT_OPTIONS, getPluginDoughnut } from './chartHelpers';
import { ChartCard } from './ChartCard';

export const DoughnutWrapper = styled.div`
  height: 140px;
  width: 140px;
  display: flex;
  justify-content: center;
`;

type AverageRecyclingRatesChartProps = {
  averageRecyclingRate: number | null;
};

export const AverageRecyclingRatesChart: React.FC<AverageRecyclingRatesChartProps> = memo(
  ({ averageRecyclingRate }) => {
    return (
      <ChartCard title="Average Recycling Rates" sx={{ height: '246px' }}>
        <DoughnutWrapper>
          <Doughnut
            key={Math.random()} // random key value allow to update data im plugins
            plugins={[getPluginDoughnut((averageRecyclingRate || 0) * 100)]}
            options={DOUGHNUT_OPTIONS}
            data={{
              labels: ['Average recycle rate'],
              datasets: averageRecyclingRate
                ? [
                    {
                      data: [
                        (averageRecyclingRate || 0) * 100,
                        100 - (averageRecyclingRate || 0) * 100
                      ],
                      backgroundColor: ['rgba(207, 52, 42, 1)', 'rgba(247, 227, 227, 1)'],
                      borderColor: ['rgba(207, 52, 42, 1)', 'rgba(247, 227, 227, 1)'],
                      borderWidth: 1
                    }
                  ]
                : []
            }}
          />
        </DoughnutWrapper>
      </ChartCard>
    );
  }
);
