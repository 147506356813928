import React, { useCallback, useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import styled from 'styled-components/macro';
import { tableCellClasses } from '@mui/material/TableCell';
import TableRowComponent from './TableRow';
import Loader from '../Loader';
import { TableTotal } from './TableTotal';
import { savePrice } from '../../redux/slices/TotalSlice';
import { useAppDispatch } from '../../hooks/useReduxHelper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100,
    height: 55,
    zIndex: 10
  }
}));

type ResultProps = {
  resultData: any;
  isLoading: boolean;
  serviceModelEnabled: Array<number>;
  sortBy: string;
  combineStreams: number;
  isGeneralCollectionError: boolean;
};
export const ResultTable: React.FC<ResultProps> = ({
  resultData,
  isLoading,
  serviceModelEnabled,
  sortBy,
  combineStreams,
  isGeneralCollectionError
}) => {
  const [totalData, setTotalData] = useState([]);
  const [selectedSupplierIndex, setSelectedSupplierIndex] = useState<number>(0);
  const dispatch = useAppDispatch();
  const isContainerSupplierData = useCallback((data) => {
    if (data?.recommended_supplier_collection?.data[0]?.containers) {
      return data?.recommended_supplier_collection;
    } else {
      return data?.recommended_supplier_collection?.data[0];
    }
  }, []);
  useEffect(() => {
    const deffaultData = resultData?.result?.map((row: any, index: number) => {
      return { supplier: isContainerSupplierData(row), index };
    });
    setTotalData(deffaultData);
    // eslint-disable-next-line
  }, [resultData]);
  useEffect(() => {
    dispatch(savePrice(totalData));
  }, [dispatch, totalData]);
  return (
    <div>
      <TableContainer
        sx={{
          minHeight: 300,
          maxHeight: 640,
          // height: 450,
          bgcolor: 'background.paper'
        }}
        component={Paper}
      >
        <Table size="small" aria-label="enhanced table" stickyHeader sx={{ minHeight: '300px' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>What's Being Collected</StyledTableCell>
              <StyledTableCell>Company Details</StyledTableCell>
              <StyledTableCell align="center">Service Model</StyledTableCell>
              <StyledTableCell align="center">Vehicle Size</StyledTableCell>
              <StyledTableCell align="center">Weight, Lbs</StyledTableCell>
              <StyledTableCell align="center">Quote Price, $</StyledTableCell>
              <StyledTableCell align="center">Recycle Percentage, %</StyledTableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} sx={{ height: 300 }}>
                  <Loader />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {!isGeneralCollectionError && (
                <>
                  {resultData?.result?.map((row: any, index: number) => {
                    return (
                      <TableRowComponent
                        key={`${index}-table`}
                        serviceModelEnabled={serviceModelEnabled}
                        row={row}
                        sortBy={sortBy}
                        index={index}
                        combineStreams={combineStreams}
                        setSelectedSupplierIndex={setSelectedSupplierIndex}
                      />
                    );
                  })}
                  <TableTotal
                    resultData={resultData}
                    selectedSupplierIndex={selectedSupplierIndex}
                  />
                </>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};
