import React, { useState, useEffect, Dispatch, SetStateAction, useMemo } from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
  Collapse,
  TableBody,
  TableContainer,
  Divider,
  TablePagination
} from '@mui/material';
import styled from 'styled-components/macro';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { resultAPI } from '../../services';
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxHelper';
import {
  confirmedItems,
  updateItems,
  clearItems,
  changeItems
} from '../../redux/slices/ConfirmSlice';
import {
  calculateFreeBlock,
  clearResult,
  saveItems,
  updateSavedItems
} from '../../redux/slices/FreeBlockSlice';
import { updatePrice } from '../../redux/slices/TotalSlice';
import useLogOut from '../../hooks/useLogOut';
import Loader from '../Loader';
import Link from '@mui/material/Link';
import { correctLink } from '../../utils/correctLinks';
import { formatNumber } from '../../utils/formatHelpers';

type TableRowComponentProps = {
  row: any;
  serviceModelEnabled: Array<number>;
  sortBy: string;
  index: number;
  combineStreams: number;
  setSelectedSupplierIndex: Dispatch<SetStateAction<number>>;
};
const LIMIT = 10;
const PERCENT = 100;
const DROP_OFF = 'Customer drop-off';
const MAX_LBS = 1000;
const ERROR_DROP_OFF_MESSAGE =
  'The collection items exceed the parameters for an indicative quote, please contact the recycling suppliers directly.';

const StyledTableCellError = styled(TableCell)(() => ({
  borderTop: '1px solid rgba(224,224,224,1)'
}));

const StyledButton = styled(Button)({
  fontSize: '0.75rem',
  lineHeight: '0.75px',
  padding: '0px 8px'
});

const BoldTableCell = styled(TableCell)(() => ({
  fontWeight: 'bold'
}));

const BoldTypography = styled(Typography)(() => ({
  fontWeight: 'bold'
}));

const getPricePerCollection = (item: any) => {
  // can be 0
  return item.pricePerCollection !== null && item.pricePerCollection !== undefined
    ? item.pricePerCollection
    : item.price_per_collection;
};

const getRecyclingRate = (item: any) => {
  // can be 0
  return item.recyclingRate !== null && item.recyclingRate !== undefined
    ? item.recyclingRate
    : item.recycling_rate;
};

const TableRowComponent: React.FC<TableRowComponentProps> = ({
  row,
  serviceModelEnabled,
  sortBy,
  index,
  combineStreams,
  setSelectedSupplierIndex
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [defaultSupplier, setDefaultSupplier] = useState(
    row?.recommended_supplier_collection?.data[0]
  );

  const defaultSupplierId = useMemo(() => {
    if (defaultSupplier?.id) {
      return defaultSupplier?.id;
    }
    if (defaultSupplier?.containers?.length > 0) {
      return defaultSupplier?.containers[0]?.id;
    }
    return null;
  }, [defaultSupplier]);

  const [limit, setLimit] = useState(LIMIT);
  const [offset, setOffset] = useState(null);
  const [page, setPage] = useState(0);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { saved_data } = useAppSelector((state) => state.freeBlockSlice);
  const [errStatus, setErrStatus] = useState(null);
  const { logOut } = useLogOut(errStatus);
  const {
    data: resultData = { result: {} },
    isLoading,
    isError,
    error
  } = resultAPI.useFetchResultCollectionsFilterQuery(
    {
      id,
      waste_type_id: defaultSupplier?.waste_type_id,
      limit,
      offset,
      service_models: JSON.stringify(serviceModelEnabled),
      sortBy,
      combine_streams: combineStreams
    },
    { skip: !open, refetchOnMountOrArgChange: true }
  );
  const convertResultData: any = resultData && Object.values(resultData?.result)[0];
  // HANDLERS
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setOffset(newPage * limit);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  const errorHandler = (error: any) => {
    let message: string | any = 'Something went wrong!';
    if (error?.data?.errors) {
      const msg = Object.values(error?.data?.errors).map((item: any) => item?.message || item);
      message = msg[0];
    }
    if (error?.originalStatus === 401) {
      setErrStatus(error?.originalStatus);
      message = 'You are not authorized!';
    }
    enqueueSnackbar(message, {
      variant: 'error'
    });
  };

  // HOOKS
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (errStatus && typeof logOut === 'function') {
      logOut();
    }
  }, [errStatus, logOut]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (saved_data.length) {
      dispatch(calculateFreeBlock(saved_data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved_data]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (row?.recommended_supplier_collection?.data?.length) {
      const confirmed_collections = row?.recommended_supplier_collection?.data[0]?.containers
        ? row?.recommended_supplier_collection?.data[0]?.containers.map((el: any) => {
            return {
              total_weight_lbs: el?.weightBeingCollected,
              supplier_container_collection_id: el?.id
            };
          })
        : [
            {
              total_weight_lbs: row?.total_weight_lbs,
              supplier_container_collection_id: row?.recommended_supplier_collection?.data[0]?.id
            }
          ];
      dispatch(confirmedItems(confirmed_collections));
      setDefaultSupplier(row?.recommended_supplier_collection?.data[0]);
      const savedDataItem = {
        waste_type_name:
          row?.recommended_supplier_collection?.data[0]?.collection_info?.waste_type_name,
        total_weight_lbs: row?.total_weight_lbs,
        price_per_collection: row?.recommended_supplier_collection?.data[0]?.price_per_collection,
        free_of_charge: !!row?.recommended_supplier_collection?.data[0]?.free_of_charge
      };
      dispatch(saveItems(savedDataItem));
    }

    return () => {
      dispatch(clearItems());
      dispatch(clearResult());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    dispatch(updatePrice({ supplier: defaultSupplier, index }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSupplier]);

  // const formatNumber = (number: string) => {
  //   const mobileNumber = number.length ? `+${number}` : '';
  //   return number?.charAt(0) === '+' ? number : mobileNumber;
  // };

  const isDropOffError = useMemo(() => {
    const areManyContainers =
      defaultSupplier?.collection_info?.service_model_name === DROP_OFF &&
      defaultSupplier?.containers?.length > 0;
    if (areManyContainers) {
      const weightSum = defaultSupplier?.containers.reduce(
        (accum: number, current: unknown) => accum + (current?.weightBeingCollected || 0),
        0
      );
      return weightSum > MAX_LBS;
    }
    return false;
  }, [defaultSupplier]);

  return (
    <>
      {row?.recommended_supplier_collection?.data[0] ? (
        <>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <BoldTableCell>
              {row?.waste_type_names?.join(', ') ||
                defaultSupplier?.collection_info?.waste_type_name}
            </BoldTableCell>
            <TableCell component="th" id="id" scope="row" padding="normal">
              <Box sx={{ width: '180px' }}>
                <Typography variant="subtitle2">
                  {defaultSupplier?.collection_info?.company_name}
                </Typography>
                <Typography>Ph. #: {defaultSupplier?.collection_info?.phone_number}</Typography>
                <Typography>
                  <Link
                    href={correctLink(defaultSupplier?.collection_info?.website)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {defaultSupplier?.collection_info?.website}
                  </Link>
                </Typography>
              </Box>
            </TableCell>
            <TableCell align="center">
              {defaultSupplier?.collection_info?.service_model_name}
            </TableCell>
            {defaultSupplier?.message || isDropOffError ? (
              <TableCell align="center" colSpan={4}>
                <Typography>{defaultSupplier?.message || ERROR_DROP_OFF_MESSAGE}</Typography>
              </TableCell>
            ) : (
              <>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {defaultSupplier?.containers ? (
                      defaultSupplier?.containers.map((el, index: number) => (
                        <Typography key={index} sx={{ whiteSpace: 'nowrap' }}>
                          {el?.container_name}
                        </Typography>
                      ))
                    ) : (
                      <Typography>{defaultSupplier?.collection_info?.container_name}</Typography>
                    )}
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {defaultSupplier?.containers ? (
                      defaultSupplier?.containers.map((el, index: number) => (
                        <Typography key={index} sx={{ whiteSpace: 'nowrap' }}>
                          {formatNumber(el?.weightBeingCollected)}
                        </Typography>
                      ))
                    ) : (
                      <Typography>{formatNumber(row?.total_weight_lbs)}</Typography>
                    )}
                  </Box>
                </TableCell>
                <BoldTableCell align="center">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {+defaultSupplier?.price_per_collection === 0 ??
                    +row?.recommended_supplier_collection?.pricePerCollection === 0 ? (
                      'FREE'
                    ) : defaultSupplier?.containers ? (
                      defaultSupplier?.containers.map((el, index: number) => (
                        <BoldTypography
                          key={index}
                        >{`$${el?.price_per_collection}`}</BoldTypography>
                      ))
                    ) : (
                      <BoldTypography>{`$${
                        defaultSupplier?.price_per_collection ??
                        row?.recommended_supplier_collection?.pricePerCollection
                      }`}</BoldTypography>
                    )}
                  </Box>
                </BoldTableCell>
                <TableCell align="center">{`${Math.round(
                  getRecyclingRate(defaultSupplier) * PERCENT
                )}%`}</TableCell>
              </>
            )}
          </TableRow>
          <TableRow key={`${row?.total_items_weight_ustons}-row`}>
            {row?.recommended_supplier_collection?.data?.length > 1 && (
              <TableCell colSpan={8} sx={{ height: 0, background: '#e9f0fb' }}>
                <StyledButton
                  color="primary"
                  variant={'text'}
                  onClick={() => setOpen(!open)}
                  fullWidth
                >
                  {open ? 'Close all results...' : 'List all results...'}
                </StyledButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {isLoading && <Loader />}
                  <TableContainer sx={{ maxHeight: 440, background: 'white' }}>
                    <Table
                      sx={{ minWidth: '850px' }}
                      size="small"
                      aria-label="purchases"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>What's Being Collected</TableCell>
                          <TableCell>Company Details</TableCell>
                          <TableCell align="center">Service Model</TableCell>
                          <TableCell align="center">Vehicle Size</TableCell>
                          <TableCell align="center">Weight, Lbs</TableCell>
                          <TableCell align="center">Quote Price, $</TableCell>
                          <TableCell align="center">Recycle Percentage, %</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {convertResultData?.recommended_supplier_collection?.data?.map(
                          (item, index) => (
                            <TableRow
                              tabIndex={-1}
                              key={`${item.id}-data`}
                              selected={
                                (item?.id ? item.id : item?.containers[0]?.id) === defaultSupplierId
                              }
                            >
                              <BoldTableCell>
                                {item?.collection_info?.waste_type_name}
                              </BoldTableCell>
                              <TableCell component="th" id="id" scope="row" padding="normal">
                                <Box>
                                  <Typography variant="subtitle2">
                                    {item?.collection_info?.company_name}
                                  </Typography>
                                  <Typography>
                                    Ph. #: {item?.collection_info?.phone_number}
                                  </Typography>
                                  <Typography>
                                    <Link
                                      href={correctLink(item?.collection_info?.website)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item?.collection_info?.website}
                                    </Link>
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                {item?.collection_info?.service_model_name}
                              </TableCell>
                              <TableCell align="center">
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                >
                                  {item?.containers ? (
                                    item?.containers.map((el, index: number) => (
                                      <Typography key={index} sx={{ whiteSpace: 'nowrap' }}>
                                        {el?.container_name}
                                      </Typography>
                                    ))
                                  ) : (
                                    <Typography>{item?.collection_info?.container_name}</Typography>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                {formatNumber(convertResultData?.total_weight_lbs)}
                              </TableCell>
                              <BoldTableCell align="center">
                                {+getPricePerCollection(item) === 0
                                  ? 'FREE'
                                  : `$${getPricePerCollection(item)}`}
                              </BoldTableCell>
                              <TableCell align="center">{`${Math.round(
                                getRecyclingRate(item) * PERCENT
                              )}%`}</TableCell>
                              <TableCell sx={{ cursor: 'pointer' }} align="center">
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    setDefaultSupplier(item);
                                    setSelectedSupplierIndex(index);
                                    setOpen(false);
                                    if (item?.id) {
                                      const confirmed_collections = {
                                        total_weight_lbs: row?.total_weight_lbs,
                                        supplier_container_collection_id: item?.id
                                      };
                                      dispatch(updateItems(confirmed_collections));
                                    } else {
                                      const confirmed_collections = item?.containers.map(
                                        (el: any) => {
                                          return {
                                            total_weight_lbs: el?.weightBeingCollected,
                                            supplier_container_collection_id: el?.id
                                          };
                                        }
                                      );
                                      dispatch(changeItems(confirmed_collections));
                                    }
                                    const savedDataItem = {
                                      waste_type_name: item.collection_info?.waste_type_name,
                                      total_weight_lbs: row?.total_weight_lbs,
                                      price_per_collection: item.price_per_collection
                                        ? item.price_per_collection
                                        : item.pricePerCollection,
                                      free_of_charge: !!item?.free_of_charge
                                    };
                                    dispatch(updateSavedItems(savedDataItem));
                                  }}
                                  disabled={
                                    (item?.id ? item.id : item?.containers[0]?.id) ===
                                    defaultSupplierId
                                  }
                                >
                                  {(item?.id ? item.id : item?.containers[0]?.id) ===
                                  defaultSupplierId
                                    ? 'SELECTED'
                                    : 'SELECT'}
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider />
                  <TablePagination
                    sx={{ bgcolor: 'background.paper' }}
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={convertResultData?.recommended_supplier_collection?.total || 10}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Collapse>
              </TableCell>
            )}
          </TableRow>
        </>
      ) : (
        <TableRow>
          <StyledTableCellError colSpan={7} align="center" size="medium" variant="body">
            {row?.waste_type_name} - cannot be collected. There are no suppliers that can collect
            this waste type.
          </StyledTableCellError>
        </TableRow>
      )}
    </>
  );
};
export default TableRowComponent;
