import merge from 'deepmerge';
import { green, grey, red } from '@mui/material/colors';
import { THEMES } from '../constants';

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 '
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  mainColor: '#344563',
  palette: {
    mode: 'light',
    primary: {
      main: '#CF342A',
      contrastText: '#FFF'
    },
    secondary: {
      main: customBlue[500],
      contrastText: '#FFF'
    },
    background: {
      default: '#F7F9FC',
      paper: '#FFF'
    }
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800]
    },
    indicator: {
      background: customBlue[600]
    }
  },
  footer: {
    color: grey[500],
    background: '#FFF'
  },
  sidebar: {
    color: grey[200],
    background: '#233044',
    header: {
      color: grey[200],
      background: '#233044',
      brand: {
        color: customBlue[500]
      }
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500]
      }
    },
    badge: {
      color: '#FFF',
      background: customBlue[500]
    }
  }
};

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  mainColor: '#344563',
  palette: {
    mode: 'light'
  },
  header: {
    color: '#344563',
    background: '#FFF',
    search: {
      color: grey[100]
    },
    indicator: {
      background: red[700]
    }
  },
  sidebar: {
    color: grey[900],
    background: '#FFF',
    header: {
      color: '#FFF',
      background: customBlue[800],
      brand: {
        color: '#FFFFFF'
      }
    },
    footer: {
      color: grey[800],
      background: '#F7F7F7',
      online: {
        background: green[500]
      }
    }
  }
});

const variants: Array<VariantType> = [lightVariant];

export default variants;

export type VariantType = {
  name: string;
  mainColor: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};
