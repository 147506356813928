import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import Loader from './Loader';
import { useAppDispatch, useAppSelector } from '../hooks/useReduxHelper';
import { calculateFreeBlock, clearResult, saveItems } from '../redux/slices/FreeBlockSlice';
import Link from '@mui/material/Link';
import { QuoteCollection } from '../types';
import { correctLink } from '../utils/correctLinks';
import { formatNumber } from '../utils/formatHelpers';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100,
    height: 55
  }
}));
const StyledTableCellTotal = styled(TableCell)(() => ({
  borderTop: '1px solid rgba(224,224,224,1)',
  fontWeight: 'bold'
}));
// eslint-disable-next-line no-empty-pattern
const StyledTableRowTotal = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A100,
  height: '50px'
}));

const BoldTableCell = styled(TableCell)(() => ({
  fontWeight: 'bold'
}));

const BoldTypography = styled(Typography)(() => ({
  fontWeight: 'bold'
}));

const PERCENT = 100;
const DEFAULT_PRICE = 0;
type ConfirmTableProps = {
  rowsData: any;
  isLoading?: boolean;
};

const totalFunc = (arr) => {
  const reduceHelper = (accumulator, curr) => accumulator + curr;
  return arr?.length ? +arr?.reduce(reduceHelper) : DEFAULT_PRICE;
};

export const ConfirmTable: React.FC<ConfirmTableProps> = ({ rowsData, isLoading }) => {
  const dispatch = useAppDispatch();
  const { saved_data } = useAppSelector((state) => state.freeBlockSlice);

  useEffect(() => {
    if (saved_data.length) {
      dispatch(calculateFreeBlock(saved_data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved_data]);
  useEffect(() => {
    if (rowsData) {
      const confirmed_collections =
        rowsData?.quote?.confirmed_quote_collection?.map((item: any) => ({
          waste_type_name: item?.collection_info?.waste_type_name,
          total_weight_lbs: item?.total_weight_lbs,
          price_per_collection: item?.collection_price,
          free_of_charge: !!item?.free_collection
        })) || {};
      dispatch(saveItems(Object.values(confirmed_collections)));
    }

    return () => {
      dispatch(clearResult());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsData]);

  const weightArr = rowsData?.quote?.confirmed_quote_collection?.map((item: any) =>
    item.map((el: QuoteCollection) => el?.total_weight_lbs)
  );
  const priceArr = rowsData?.quote?.confirmed_quote_collection?.map((item: any) =>
    item.map((el: QuoteCollection) => el?.collection_price)
  );
  const recycleArr = rowsData?.quote?.confirmed_quote_collection?.map(
    (item: any) => item[0]?.collection_info?.recycling_rate * PERCENT
  );
  const totalWeight = totalFunc(weightArr?.flat());
  const totalPrice = totalFunc(priceArr?.flat());
  const totalRecycle = totalFunc(recycleArr) / recycleArr?.length;
  // const formatNumber = (number: string) => {
  //   const mobileNumber = number.length ? `+${number}` : '';
  //   return number?.charAt(0) === '+' ? number : mobileNumber;
  // };
  return (
    <div>
      <TableContainer sx={{ maxHeight: 640, bgcolor: 'background.paper' }} component={Paper}>
        <Table sx={{ minWidth: '850px' }} size="small" aria-label="purchases" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>What's Being Collected</StyledTableCell>
              <StyledTableCell>Company Details</StyledTableCell>
              <StyledTableCell align="center">Service Model</StyledTableCell>
              <StyledTableCell align="center">Vehicle Size</StyledTableCell>
              <StyledTableCell align="center">Weight, Lbs</StyledTableCell>
              <StyledTableCell align="center">Quote Price, $</StyledTableCell>
              <StyledTableCell align="center">Recycle Percentage, %</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={7} sx={{ height: 300 }}>
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {rowsData?.quote?.confirmed_quote_collection?.map((item: any, index: number) => {
              const firstItem = item[0];
              return (
                <TableRow key={`${index}-data-table`}>
                  <BoldTableCell>
                    {firstItem?.waste_types_names?.join(', ') ||
                      firstItem?.collection_info?.waste_type_name}
                  </BoldTableCell>
                  <TableCell component="th" id="id" scope="row" padding="normal">
                    <Box sx={{ width: '180px' }}>
                      <Typography variant="subtitle2">
                        {firstItem?.collection_info?.company_name}
                      </Typography>
                      <Typography>Ph. #: {firstItem?.collection_info?.phone_number}</Typography>
                      <Typography>
                        <Link
                          href={correctLink(firstItem?.collection_info?.website)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {firstItem?.collection_info?.website}
                        </Link>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {firstItem?.collection_info?.service_model_name}
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {item?.map((el: QuoteCollection, index: number) => (
                        <Typography
                          key={`${index + el?.collection_info?.container_name}`}
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {el?.collection_info?.container_name}
                        </Typography>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {item?.map((el: QuoteCollection, index: number) => (
                        <Typography
                          key={`${index + el?.total_weight_lbs}`}
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {formatNumber(el?.total_weight_lbs)}
                        </Typography>
                      ))}
                    </Box>
                  </TableCell>
                  <BoldTableCell align="center">
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {item?.map((el: QuoteCollection, index: number) => (
                        <BoldTypography
                          key={`${index + el?.collection_price}`}
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          {!el?.collection_price ? 'FREE' : `$${el?.collection_price}`}
                        </BoldTypography>
                      ))}
                    </Box>
                  </BoldTableCell>
                  <TableCell align="center">{`${(
                    firstItem?.collection_info?.recycling_rate * PERCENT
                  ).toFixed(2)}%`}</TableCell>
                </TableRow>
              );
            })}
            <StyledTableRowTotal>
              <StyledTableCellTotal colSpan={3} />
              <StyledTableCellTotal align="center">Total:</StyledTableCellTotal>
              <StyledTableCellTotal align="center">
                {formatNumber(totalWeight)}
              </StyledTableCellTotal>
              <StyledTableCellTotal align="center">
                {totalPrice === DEFAULT_PRICE ? 'FREE' : `$${totalPrice}`}
              </StyledTableCellTotal>
              <StyledTableCellTotal align="center">
                {totalRecycle.toFixed(2)} %
              </StyledTableCellTotal>
            </StyledTableRowTotal>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
